import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from './api';
import type { DeliveryChannel, IdType, PublicProject } from './types';

const publicProjectDeliveryChannelsKey = 'public-project-delivery-channels';

export const usePublicProjectsDeliveryChannels = (
  options?: Omit<UseQueryOptions<DeliveryChannel[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<DeliveryChannel[]>(
    publicProjectDeliveryChannelsKey,
    async () => {
      try {
        return await api
          .get('public-projects/subscribed', { retry: 0 })
          .json<DeliveryChannel[]>();
      } catch (_e) {
        // return empty if not authorized or... other error :sweat:
        return [];
      }
    },
    options
  );

export const useAllPublicProjects = () =>
  useQuery<PublicProject[]>('all-public-projects', () =>
    api.get('public-projects/all').json<PublicProject[]>()
  );

export const useSubscribeToPublicProject = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      selectedTriggers: {
        transportId: string;
        triggerId: string;
      }[];
      projectId: IdType;
    }
  >(
    async ({ projectId, selectedTriggers }) => {
      api.post(`public-projects/${projectId}`, {
        json: {
          selectedTriggers,
        },
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(publicProjectDeliveryChannelsKey);
      },
    }
  );
};
