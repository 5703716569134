import type { ComponentType, FC, ReactElement } from 'react';
import React from 'react';

import Link from 'next/link';

import { classNames } from 'utils';

import { PopoverPanelItemIcon } from './PopoverPanelItemIcon';
import type { PopoverPanelItemPassedProps } from './types';

type PopoverPanelItemProps = {
  href?: string;
  leadingIcon?: ComponentType | ReactElement;
} & PopoverPanelItemPassedProps;

export const PopoverPanelItem: FC<PopoverPanelItemProps> = ({
  children,
  onClick,
  close,
  href,
  leadingIcon: LeadingIcon,
  theme = 'popover',
}) => {
  const className = classNames(
    'flex items-center px-2 w-full text-sm font-medium text-pgray-700 hover:bg-pgray-50 focus:bg-pgray-50 rounded',
    theme === 'popover' ? 'h-10' : 'h-12'
  );

  if (href) {
    return (
      <Link href={href}>
        <a className={className} onClick={close}>
          {LeadingIcon ? (
            <PopoverPanelItemIcon theme={theme}>
              {React.isValidElement(LeadingIcon) ? (
                LeadingIcon
              ) : (
                <LeadingIcon />
              )}
            </PopoverPanelItemIcon>
          ) : null}
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClick}>
      {LeadingIcon ? (
        <PopoverPanelItemIcon theme={theme}>
          {React.isValidElement(LeadingIcon) ? LeadingIcon : <LeadingIcon />}
        </PopoverPanelItemIcon>
      ) : null}
      {children}
    </button>
  );
};
