import { useEffect } from 'react';

import { useQueryClient } from 'react-query';

import {
  getBillingPaymentInfo,
  getBillingPaymentInfoKey,
  getBillingUsage,
  getBillingUsageKey,
  getMonitoringUsage,
  getMonitoringUsageKey,
  getSubscription,
  getSubscriptionKey,
  getTransports,
  getTransportsKey,
} from 'api';
import { useCurrentUser } from 'store';

const prefetchData: [string, () => Promise<unknown>][] = [
  [getSubscriptionKey(), getSubscription],
  [getBillingUsageKey(), getBillingUsage],
  [getBillingPaymentInfoKey(), getBillingPaymentInfo],
  [getMonitoringUsageKey(), getMonitoringUsage],
  [getTransportsKey(), getTransports],
];

export const PrefetchData = () => {
  const { currentUser } = useCurrentUser();
  const client = useQueryClient();

  useEffect(() => {
    if (currentUser) {
      prefetchData.forEach(([key, fetchFunction]) => {
        client.prefetchQuery(key, fetchFunction);
      });
    }
  }, [client, currentUser]);

  return null;
};
