import React from 'react';

import type { Control, FieldValues } from 'react-hook-form';
import { useFormState } from 'react-hook-form';

import { Button } from 'components/button';

type Props<TFieldValues extends FieldValues = FieldValues> = {
  onCancel: () => void;
  control?: Control<TFieldValues>;
};

export const DefaultFormButtons = <
  TFieldValues extends FieldValues = FieldValues
>({
  onCancel,
  control,
}: Props<TFieldValues>) => {
  const { errors, isSubmitting } = useFormState({ control });

  const isDisabled = Object.keys(errors).length > 0 || isSubmitting;

  return (
    <div className="mt-8 flex space-x-4">
      <Button label="Cancel" fullWidth theme="outline" onClick={onCancel} />
      <Button
        label={isSubmitting ? 'Saving...' : 'Save'}
        fullWidth
        type="submit"
        disabled={isDisabled}
      />
    </div>
  );
};
