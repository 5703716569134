import type { UserStreamBlockchain } from './types';
import { UserdataType } from './types';

export const userdataTypeName: Record<UserdataType, string> = {
  [UserdataType.Integer]: 'Integer',
  [UserdataType.String]: 'String',
  [UserdataType.Boolean]: 'Boolean',
  [UserdataType.EthereumAddress]: 'Ethereum Address',
  [UserdataType.BitcoinAddress]: 'Bitcoin Address',
  [UserdataType.DashAddress]: 'Dash Address',
  [UserdataType.AlgorandAddress]: 'Algorand Address',
  [UserdataType.BinanceAddress]: 'Binance Smart Chain Address',
  [UserdataType.HuobiEcoAddress]: 'Huobi Eco Chain Address',
  [UserdataType.SolanaAddress]: 'Solana Address',
  [UserdataType.PolkadotAddress]: 'Polkadot Address',
  [UserdataType.CasperAddress]: 'Casper Address',
  [UserdataType.MaticAddress]: 'Polygon Address',
};

export const userStreamBlockchains: Record<UserStreamBlockchain, string> = {
  eth: 'Ethereum',
  bsc: 'Binance Smart-Chain',
  heco: 'Huobi ECO Chain',
  matic: 'Polygon',
};
