import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

export const Overlay = () => (
  <Transition.Child
    as={Fragment}
    enter="ease-in-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-focus"
    entered="opacity-focus"
    leave="ease-in-out duration-300"
    leaveFrom="opacity-focus"
    leaveTo="opacity-0"
  >
    <Dialog.Overlay className="absolute inset-0 bg-black opacity-focus transition-opacity" />
  </Transition.Child>
);
