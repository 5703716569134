import type { ElementType } from 'react';
import React from 'react';

import { RadioGroup } from '@headlessui/react';

import { classNames } from 'utils';

import { SubLabel } from '../common/SubLabel';
import type { Option, RadioGroupProps } from './types';

export const RadioGroupBase = ({
  label,
  name,
  options,
  value,
  onChange,
  hasError,
  errorText,
  layout = 'vertical',
}: RadioGroupProps) => (
  <RadioGroup<ElementType, Option> value={value} onChange={onChange}>
    <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
    <div
      className={classNames(
        layout === '2xgrid' ? 'grid grid-cols-2 gap-2' : 'space-y-2'
      )}
    >
      {options.map((option) => (
        <RadioGroup.Option
          key={option.value}
          value={option}
          className={({ active }) =>
            classNames(
              'relative flex cursor-pointer justify-between rounded-lg border bg-white p-3 shadow-sm focus:outline-none',
              active ? 'border-pblue-500' : 'border-pgray-300'
            )
          }
        >
          {({ checked }) => (
            <>
              <div className="flex items-start">
                <div>
                  <RadioGroup.Label
                    as="div"
                    className="mb-2 font-medium text-pgray-700"
                  >
                    {option.label}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="div"
                    className="text-xs text-pgray-700"
                  >
                    <p className="sm:inline">{option.description}</p>
                  </RadioGroup.Description>
                </div>
              </div>
              <div
                className={classNames(
                  checked ? 'border-pblue-500' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-lg border-2'
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
    <SubLabel name={name} hasError={hasError} errorText={errorText} />
  </RadioGroup>
);

RadioGroupBase.displayName = 'RadioGroupBase';
