import type { FC } from 'react';
import { Fragment } from 'react';

import { Transition } from '@headlessui/react';

export const Body: FC = ({ children }) => {
  return (
    <Transition.Child
      as={Fragment}
      enter="transform transition ease-in-out duration-300 sm:duration-300"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-300 sm:duration-300"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="w-screen sm:w-84">
        <div className="flex h-full flex-col overflow-y-scroll bg-white px-4 pt-4 shadow-xl">
          {children}
        </div>
      </div>
    </Transition.Child>
  );
};
