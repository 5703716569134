import type { SetState } from 'zustand';
import create from 'zustand';

import type { TelegramTransportPrivacy, TransportType } from 'api';

/**
 * Step State
 */
type StepState = { step: number; setStep: (step: number) => void };

const createStep = (set: SetState<StepState>) => ({
  step: 1,
  setStep: (step) => set(() => ({ step })),
});

/**
 * Form State
 */
type FormData = {
  type?: TransportType;
  privacy?: TelegramTransportPrivacy;
};

type FormDataState = {
  formData: FormData;
  setFormData: (data: Partial<FormData>) => void;
};

const createFormData = (set: SetState<FormDataState>) => ({
  formData: {},
  setFormData: (data) =>
    set(({ formData }) => ({ formData: { ...formData, ...data } })),
});

/**
 * Created transport
 */
type TransportState = {
  transportId: string | null;
  setTransportId: (transportId: string) => void;
};

const createTransport = (set: SetState<TransportState>) => ({
  transportId: null,
  setTransportId: (transportId) => set(() => ({ transportId })),
});

/**
 * Reset handler
 */
type ResetState = { reset: () => void };

const createStateReset = (
  set: SetState<StepState & FormDataState & TransportState>
) => ({
  reset: () => set(() => ({ step: 1, formData: {}, transportId: null })),
});

type State = StepState & FormDataState & ResetState & TransportState;

export const useAddTransportStore = create<State>((set) => ({
  ...createStep(set),
  ...createFormData(set),
  ...createStateReset(set),
  ...createTransport(set),
}));
