import create from 'zustand';
import shallow from 'zustand/shallow';

type MobileViewStore = {
  isMobileView: boolean;
  setIsMobileView: (isMobileView: boolean) => void;
};

const useMobileViewStore = create<MobileViewStore>((set) => ({
  isMobileView: false,
  setIsMobileView: (isMobileView) => set(() => ({ isMobileView })),
}));

export const useMobileView = () =>
  useMobileViewStore(
    ({ isMobileView, setIsMobileView }) => ({
      isMobileView,
      setIsMobileView,
    }),
    shallow
  );
