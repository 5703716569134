import type { Control, FieldValues } from 'react-hook-form';
import { useFormState } from 'react-hook-form';

import { Button } from 'components/button';

import { useAddTransportStore } from './store';

type Props<TFieldValues extends FieldValues = FieldValues> = {
  isFinalStep?: boolean;
  control: Control<TFieldValues>;
};

export const PrevNextButtons = <
  TFieldValues extends FieldValues = FieldValues
>({
  isFinalStep,
  control,
}: Props<TFieldValues>) => {
  const { step, setStep } = useAddTransportStore();
  const { isSubmitting } = useFormState({ control });

  if (isFinalStep) {
    return (
      <div className="mt-8 flex justify-between">
        <Button label="Previous" onClick={() => setStep(step - 1)} />
        <Button
          label={isSubmitting ? 'Submitting...' : 'Submit'}
          type="submit"
          disabled={isSubmitting}
        />
      </div>
    );
  }

  if (step === 1) {
    return (
      <div className="mt-8 flex justify-end">
        <Button label="Next" type="submit" />
      </div>
    );
  }

  return (
    <div className="mt-8 flex justify-between">
      <Button label="Previous" onClick={() => setStep(step - 1)} />
      <Button label="Next" type="submit" />
    </div>
  );
};
