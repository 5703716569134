import { Button } from 'components/button';
import { useCloseSidePanel } from 'store';

export const Success = () => {
  const onClose = useCloseSidePanel();

  return (
    <>
      <p className="my-0 mb-4 text-pgray-700">
        Transport successfully created & connected, press &quot;
        <span className="font-medium">Done</span>&quot; to close this panel.
      </p>
      <div className="flex justify-end">
        <Button onClick={onClose} label="Done" />
      </div>
    </>
  );
};
