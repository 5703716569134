import create from 'zustand';

import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

type ActiveProjectStore = {
  activeProject: string;
  setActiveProject: (projectId: string | null) => void;
};

export const useActiveProject = create<ActiveProjectStore>((set) => ({
  activeProject: getLocalStorage('activeProject') || '',
  setActiveProject: (projectId: string) =>
    set(() => {
      setLocalStorage('activeProject', projectId);

      return { activeProject: projectId };
    }),
}));
