import type { FC } from 'react';

import { classNames } from '../../utils';

type Props = {
  background?: 'white' | 'gray';
  rounded?: 'lg' | 'xl' | '2xl';
  noPadding?: boolean;
  className?: string;
};

const roundedTypes: Record<Required<Props>['rounded'], string> = {
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
};

const backgrounds: Record<Required<Props>['background'], string> = {
  white: 'bg-white',
  gray: 'bg-pgray-50',
};

export const Section: FC<Props> = ({
  children,
  rounded = 'lg',
  background = 'white',
  noPadding,
  className,
}) => (
  <div
    className={classNames(
      `${roundedTypes[rounded]} ${backgrounds[background]}`,
      noPadding ? '' : 'p-4',
      className
    )}
  >
    {children}
  </div>
);
