import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';
import { object, string } from 'yup';

import type { TelegramTransportPrivacy } from 'api';
import { useCreateTransport } from 'api';
import { FormStep, Input, MultiFieldContainer } from 'components/form';
import { Section } from 'components/section';
import { useSetFormErrors } from 'hooks/useSetFormErrors';

import { PrevNextButtons } from '../PrevNextButtons';
import { useAddTransportStore } from '../store';

export const getValidationSchema = (isChannel: boolean) =>
  object({
    name: string().required('Name is required'),
    ...(isChannel
      ? { publicChannelUsername: string().required('Channel name is required') }
      : {}),
  });

const deliverToLabels: Record<TelegramTransportPrivacy, string> = {
  channel: 'Telegram channel',
  group: 'Telegram group',
  private: 'Telegram as private messages',
};

const Header = () => {
  const { formData } = useAddTransportStore();

  if (!formData.privacy) {
    return null;
  }

  return (
    <div className="mb-4">
      <Section background="gray" rounded="2xl">
        <p className="mb-2 text-sm">
          Alright, your events will be delivered to{' '}
          <span className="font-bold">{deliverToLabels[formData.privacy]}</span>
          .
        </p>
        <p className="text-sm">
          Please, give it a name. It will help you to quickly distinguish
          between various transports when you are creating a trigger.
        </p>
      </Section>
    </div>
  );
};

type FormValues = {
  name: string;
  publicChannelUsername: string;
};

const Form = () => {
  const { formData, setTransportId } = useAddTransportStore();
  const { mutateAsync: createTransport } = useCreateTransport();

  const isChannel = formData.privacy === 'channel';

  const resolver = useMemo(
    () => yupResolver(getValidationSchema(isChannel)),
    [isChannel]
  );

  const { handleSubmit, control, setError } = useForm<FormValues>({
    resolver,
    mode: 'onBlur',
    defaultValues: {
      name: '',
      publicChannelUsername: '',
    },
  });

  const setFormErrors = useSetFormErrors(setError);

  const onSubmit: SubmitHandler<NonNullProps<Required<FormValues>>> = async (
    values
  ) => {
    try {
      const transport = await createTransport({
        type: 'telegram',
        privacy: formData.privacy!,
        ...values,
      });

      setTransportId(transport.id);
    } catch (error) {
      setFormErrors(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStep
        step={3}
        control={control}
        dependantFields={[
          'privacy',
          ...(isChannel ? ['publicChannelUsername'] : []),
        ]}
        title="Set transport parameters"
        subTitle={`Transport name${isChannel ? ' and channel name' : ''}`}
      >
        <MultiFieldContainer>
          <Input
            name="name"
            control={control}
            label="Name"
            placeholder="Transport Name"
          />
          {isChannel ? (
            <Input
              name="publicChannelUsername"
              control={control}
              label="Channel Name"
              placeholder="Telegram Channel Name"
            />
          ) : null}
        </MultiFieldContainer>
      </FormStep>
      <PrevNextButtons control={control} isFinalStep />
    </form>
  );
};

export const TelegramFormStepThree = () => (
  <div className="flex flex-col">
    <Header />
    <Form />
  </div>
);
