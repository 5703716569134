import { useTransport } from 'api';

import { useAddTransportStore } from './store';

/** Naming is hard, sorry **/
export const useStoreTransport = () => {
  const { transportId } = useAddTransportStore();

  const { data: transport } = useTransport(transportId!, {
    refetchInterval: 2500,
    enabled: !!transportId,
  });

  return transport ?? null;
};
