import { useQuery } from 'react-query';

import { api } from './api';
import type { ProjectTemplatesResponse } from './types';

export const useProjectTemplates = () =>
  useQuery<ProjectTemplatesResponse>(
    ['project', 'templates'],
    async () =>
      await api.get('template/projects').json<ProjectTemplatesResponse>()
  );
