import type { FC } from 'react';

import type { PopoverProps } from 'components/popover';
import { Popover } from 'components/popover';
import { useMobileView } from 'store';

export const Dropdown: FC<
  Omit<PopoverProps, 'referenceElementWidth' | 'theme'>
> = (props) => {
  const { isMobileView } = useMobileView();

  return (
    <Popover
      {...props}
      theme="dropdown"
      referenceElementWidth={!isMobileView}
      popperOptions={{
        ...props.popperOptions,
        modifiers: [
          ...(props.popperOptions?.modifiers ?? []),
          {
            name: 'offset',
            options: {
              offset: [0, 7],
            },
          },
        ],
      }}
    />
  );
};
