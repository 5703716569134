import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';
import { mixed, object } from 'yup';

import type { TelegramTransportPrivacy } from 'api';
import type { RadioValue } from 'components/form';
import { FormStep, MultiFieldContainer, RadioGroup } from 'components/form';
import { Section } from 'components/section';

import { transportPrivacyOptions } from '../Options';
import { PrevNextButtons } from '../PrevNextButtons';
import { useAddTransportStore } from '../store';

export const validationSchema = object({
  privacy: mixed().required('Please select delivery type before proceeding'),
});

const Header = () => (
  <div className="mb-4">
    <Section background="gray" rounded="2xl">
      <p className="mb-2 text-sm">
        We will deliver your events to{' '}
        <span className="font-medium">Telegram</span>.
      </p>
      <p className="text-sm">
        Do you want them delivered as a private message, to a group or to a
        channel?
      </p>
    </Section>
  </div>
);

type FormValues = {
  privacy: RadioValue<TelegramTransportPrivacy> | null;
};

const Form = () => {
  const { formData, setFormData, setStep } = useAddTransportStore();

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      privacy:
        transportPrivacyOptions.find(
          ({ value }) => formData.privacy === value
        ) ?? null,
    },
  });

  const onSubmit: SubmitHandler<NonNullProps<Required<FormValues>>> = async (
    values
  ) => {
    setFormData({ privacy: values.privacy.value });
    setStep(3);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStep
        step={2}
        control={control}
        dependantFields={['privacy']}
        title="Set transport privacy"
        subTitle="Privacy"
      >
        <MultiFieldContainer>
          <RadioGroup
            name="privacy"
            label="Privacy setting"
            options={transportPrivacyOptions}
            control={control}
          />
        </MultiFieldContainer>
      </FormStep>
      <PrevNextButtons control={control} />
    </form>
  );
};

export const TelegramFormStepTwo = () => (
  <div className="flex flex-col">
    <Header />
    <Form />
  </div>
);
