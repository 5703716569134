import type { Transport } from 'api';
import { OutsideLinkButton } from 'components/button';
import { StyledLink } from 'components/styled-link';

import { Success } from '../Success';

export const GoogleSheetsFinalConfiguration = ({
  transport,
}: {
  transport: Transport;
}) => {
  if (!transport || transport.type !== 'google_sheets') {
    return null;
  }

  if (transport.configured) {
    return <Success />;
  }

  return (
    <div className="flex flex-col">
      <p className="my-0 mb-4 text-pgray-700">
        To finish <span className="font-medium">Google Sheets</span>{' '}
        configuration please{' '}
        <StyledLink href={transport.config.oAuth2AuthorizationUrl}>
          click the button
        </StyledLink>
      </p>
      <OutsideLinkButton
        label="Connect Google Sheets"
        fullWidth
        href={transport.config.oAuth2AuthorizationUrl}
      />
    </div>
  );
};
