import type { ElementType } from 'react';
import React from 'react';

import { classNames } from 'utils';

import { Label } from '../common/Label';
import { SubLabel } from '../common/SubLabel';
import { TrailingIcon } from './TrailingIcon';

export type InputProps = {
  name: string;
  value: string;
  label?: string;
  subLabel?: string;
  hasError?: boolean;
  errorText?: string;
  Icon?: ElementType;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const InputBase = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      subLabel,
      hasError,
      errorText = 'Please check this field',
      Icon,
      ...props
    },
    ref
  ) => (
    <div>
      <Label id={name} label={label} />
      <div className="relative rounded-md shadow-sm">
        <input
          type="text"
          className={classNames(
            'block w-full rounded-md py-2 text-sm font-medium placeholder:font-normal focus:outline-none focus:ring-0',
            hasError
              ? 'border-pred-500 text-pred-600 placeholder:text-pred-300 focus:border-pred-700'
              : 'border-pgray-300 text-pgray-700 placeholder:text-pgray-300 focus:border-pblue-500',
            Icon ? 'pr-10' : ''
          )}
          {...(hasError
            ? { 'aria-invalid': 'true', 'aria-describedby': `${name}-error` }
            : {})}
          ref={ref}
          {...props}
        />
        <TrailingIcon Icon={Icon} hasError={hasError} />
      </div>
      <SubLabel
        name={name}
        hasError={hasError}
        errorText={errorText}
        subLabel={subLabel}
      />
    </div>
  )
);

InputBase.displayName = 'Input';
