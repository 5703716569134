import type { FC } from 'react';
import React from 'react';

import { GenericLayout } from 'components/layout';
import { AddProjectSidepanel } from 'features/projects/sidepanels/AddProjectSidepanel';
import { AddTransportSidepanel } from 'features/transports/sidepanels/AddTransportSidepanel';
import { useSetActiveProject } from 'hooks/useSetActiveProject';
import { EnsureRehydrated } from 'utils/EnsureRehydrated';

import { useLogCurrentUser, useLogoutIfNoCurrentUser } from './hooks';
import { PrefetchData } from './PrefetchData';

export const LoggedInLayout: FC = ({ children }) => {
  useSetActiveProject();
  useLogoutIfNoCurrentUser();
  useLogCurrentUser();

  return (
    <EnsureRehydrated>
      <GenericLayout>
        <AddProjectSidepanel />
        <AddTransportSidepanel />
        <PrefetchData />
        {children}
      </GenericLayout>
    </EnsureRehydrated>
  );
};
