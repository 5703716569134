import React from 'react';

import { PlusCircleIcon } from '@heroicons/react/outline';
import Link from 'next/link';

import { useProjectTriggers } from 'api';
import type { TriggerStatus } from 'api/types';
import { ROUTE_SMART_TEMPLATES } from 'features/smart-templates/routes';
import { getTriggerRoute } from 'features/trigger/routes';
import { triggerStatusColors } from 'features/trigger/utils';
import { useActiveProject } from 'store';
import { classNames } from 'utils';

const TriggerMenuItem = ({
  name,
  href,
  status,
}: {
  name: string;
  href: string;
  status: TriggerStatus;
}) => (
  <Link href={href}>
    <a className="flex h-10 w-full cursor-pointer items-center rounded px-2 text-pgray-700 hover:bg-pgray-50 focus:bg-pgray-50">
      <div
        className={classNames(
          'mr-2 ml-1 h-2 w-2 shrink-0 rounded-full',
          triggerStatusColors[status]
        )}
      />
      <span className="truncate">{name}</span>
    </a>
  </Link>
);

const CreateTriggerButton = () => (
  <Link href={ROUTE_SMART_TEMPLATES}>
    <a className="flex h-10 w-full items-center rounded px-2 text-pgray-700 hover:bg-pgray-50 focus:bg-pgray-50">
      <div className="mr-2 h-6 w-6 text-pblue-500">
        <PlusCircleIcon />
      </div>
      <span className="font-medium">New Smart Trigger</span>
    </a>
  </Link>
);

export const ProjectTriggers = () => {
  const { activeProject } = useActiveProject();

  const { data = [] } = useProjectTriggers({
    projectId: activeProject,
    options: {
      enabled: !!activeProject,
    },
  });

  return (
    <>
      <h5 className="mb-2 px-2 text-xs uppercase text-pgray-500">
        Smart Triggers
      </h5>
      <div className="overflow-y-auto">
        {data.map(({ name, id, status }) => (
          <TriggerMenuItem
            name={name}
            status={status}
            href={getTriggerRoute(activeProject, id)}
            key={name}
          />
        ))}
      </div>
      <CreateTriggerButton />
    </>
  );
};
