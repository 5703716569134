import type { FC } from 'react';

type CommonProps = { name: string };

const ErrorSubLabel: FC<CommonProps> = ({ children, name }) => (
  <p className="mt-1 text-xs text-pred-600" id={`${name}-error`}>
    {children}
  </p>
);

const NormalSubLabel: FC<CommonProps> = ({ children, name }) => (
  <p className="mt-1 text-xs text-pgray-500" id={`${name}-description`}>
    {children}
  </p>
);

export type SubLabelProps = {
  name: string;
  hasError?: boolean;
  errorText?: string;
  subLabel?: string;
};

export const SubLabel = ({
  name,
  hasError,
  errorText = 'Please check this field',
  subLabel,
}: SubLabelProps) => {
  if (!subLabel && !hasError) {
    return null;
  }

  if (hasError) {
    return <ErrorSubLabel name={name}>{errorText}</ErrorSubLabel>;
  }

  if (subLabel) {
    return <NormalSubLabel name={name}>{subLabel}</NormalSubLabel>;
  }

  return null;
};
