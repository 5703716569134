import 'styles/global.scss';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { QueryClient, QueryClientProvider } from 'react-query';

import { APP_CONFIG } from 'consts/config';
import { LoggedInLayout } from 'features/default-layout';
import { useMobileViewTracker } from 'hooks/useMobileViewTracker';
import { useRouteChangeTracker } from 'hooks/useRouteChangeTracker';

const queryClient = new QueryClient();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const defaultLayout = (page: ReactElement) => (
  <LoggedInLayout>{page}</LoggedInLayout>
);

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useMobileViewTracker();
  useRouteChangeTracker();

  const getLayout = Component.getLayout ?? defaultLayout;

  return (
    <>
      {APP_CONFIG.enableTrackers === 'true' ? (
        <>
          <Script
            strategy="afterInteractive"
            id="googleTagManager"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${APP_CONFIG.googleTagManagerId}');
          `,
            }}
          />
          <Script
            strategy="afterInteractive"
            id="yandexMetrica"
            dangerouslySetInnerHTML={{
              __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(${APP_CONFIG.yandexMetrikaId}, "init", {
                 clickmap:true,
                 trackLinks:true,
                 accurateTrackBounce:true,
                 webvisor:true
            });
          `,
            }}
          />
        </>
      ) : null}
      <QueryClientProvider client={queryClient}>
        {getLayout(<Component {...pageProps} />)}
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
