import {
  ChevronDownIcon,
  CogIcon,
  CreditCardIcon,
  GlobeIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';

import { Popover, PopoverPanelItem } from 'components/popover';
import { ROUTE_ACCOUNT_SUBSCRIPTION } from 'features/account/routes';
import { ROUTE_AUTH_LOGOUT } from 'features/auth/routes';

import { BillingUsage } from './BillingUsage';
import { Header } from './Header';

const OpenButton = () => (
  <div className="flex cursor-pointer items-center text-pgray-500">
    <UserCircleIcon className="mr-1.5 h-8 w-8" />
    <ChevronDownIcon className="h-4 w-4" />
  </div>
);

export const UserToggle = () => (
  <div>
    <Popover
      openButton={<OpenButton />}
      popperOptions={{ placement: 'bottom-end' }}
    >
      <Header />
      <BillingUsage />
      <PopoverPanelItem leadingIcon={CogIcon} href="/account/transports">
        Account
      </PopoverPanelItem>
      <PopoverPanelItem
        leadingIcon={CreditCardIcon}
        href={ROUTE_ACCOUNT_SUBSCRIPTION}
      >
        Upgrade Subscription
      </PopoverPanelItem>
      <PopoverPanelItem leadingIcon={GlobeIcon} href={ROUTE_AUTH_LOGOUT}>
        Logout
      </PopoverPanelItem>
    </Popover>
  </div>
);
