import React from 'react';

import { Dialog } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/outline';

import { Section } from 'components/section';
import { SidePanel } from 'components/sidepanel';
import { classNames } from 'utils';

import { AddTransportForm } from '../forms/add-transport-form/AddTransportForm';
import { useAddTransportStore } from '../forms/add-transport-form/store';
import { useStoreTransport } from '../forms/add-transport-form/useTransport';

export const ADD_TRANSPORT_SIDEPANEL_ID = 'ADD_TRANSPORT_SIDEPANEL_ID';

const Title = () => {
  const { step } = useAddTransportStore();

  const transport = useStoreTransport();

  return (
    <Section rounded="2xl" background="gray">
      <div
        className={classNames('flex items-center', step === 1 ? 'mb-4' : '')}
      >
        <div className="mr-4 rounded-lg bg-pgray-200 p-2">
          <BellIcon className="h-6 w-6 text-pgray-500" />
        </div>
        <Dialog.Title className="text-xl font-medium text-pgray-700">
          {transport ? transport.name : 'New Transport'}
        </Dialog.Title>
      </div>
      {step === 1 ? (
        <p className="leading-5 text-pgray-700">
          Transport provides a way to connect your on-chain event to a channel
          of your need.
        </p>
      ) : null}
    </Section>
  );
};

export const AddTransportSidepanel = () => (
  <SidePanel sidePanelId={ADD_TRANSPORT_SIDEPANEL_ID} title={<Title />}>
    <AddTransportForm />
  </SidePanel>
);
