import React, { useCallback } from 'react';

import { MenuIcon } from '@heroicons/react/outline';

import { useSidebar } from 'store';

import { NotificationRegion } from '../notification';
import { UserToggle } from './user-toggle/UserToggle';

const SidebarToggle = () => {
  const { sidebarOpen, setSidebarOpen } = useSidebar();

  const onToggle = useCallback(
    () => setSidebarOpen(!sidebarOpen),
    [setSidebarOpen, sidebarOpen]
  );

  return (
    <button
      type="button"
      className="inline-flex h-6 w-6 items-center justify-center rounded-md"
      onClick={onToggle}
    >
      <span className="sr-only">Toggle sidebar</span>
      <MenuIcon className="h-6 w-6 text-pgray-700" aria-hidden="true" />
    </button>
  );
};

export const GenericHeader = () => (
  <header className="z-1 my-4 flex h-12 w-full items-center justify-between">
    <SidebarToggle />
    <UserToggle />
    <NotificationRegion />
  </header>
);
