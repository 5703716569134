import React from 'react';

import { classNames } from 'utils';

type Props = {
  theme?: 'popover' | 'dropdown';
};

export const PopoverPanelItemIcon: React.FC<Props> = ({
  children,
  theme = 'popover',
}) => (
  <div
    className={classNames(
      'mr-2 h-6 w-6',
      theme === 'popover' ? 'text-pgray-400' : 'text-pblue-500'
    )}
  >
    {children}
  </div>
);
