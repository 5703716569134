import type { Transport } from './transport';

export type IdType = string;

export type DateType = string;

export type Project = {
  id: string;
  name: string;
  apiKey: string;
  createdAt: DateType;
  templateId: string;
};

export type ProjectResponse = Project[];

export enum UserdataType {
  Integer = 'integer',
  String = 'string',
  Boolean = 'boolean',
  EthereumAddress = 'eth.address',
  BitcoinAddress = 'btc.address',
  DashAddress = 'dash.address',
  AlgorandAddress = 'algo.address',
  BinanceAddress = 'bsc.address',
  HuobiEcoAddress = 'heco.address',
  SolanaAddress = 'sol.address',
  PolkadotAddress = 'dot.address',
  CasperAddress = 'cspr.address',
  MaticAddress = 'matic.address',
}

export type MetadataField = {
  name: string;
  type: UserdataType;
};

export enum MetadataKind {
  Primitive = 'primitive',
  Struct = 'struct',
  Table = 'table',
  Abi = 'user stream',
}

export type ProjectMetadata = {
  id: IdType;
  name: string;
  projectId: IdType;
  type?: UserdataType;
  createdAt: DateType;
  updatedAt: DateType;
  kind: MetadataKind;
  fields: MetadataField[];
};

export type ProjectMetadataResponse = ProjectMetadata[];

export enum TriggerStatus {
  Created = 'created',
  Deployed = 'deployed',
  Disabled = 'disabled',
  Suspended = 'suspended',
}

export enum TriggerDisruptionReason {
  SUSPENDED_ACTION_LIMIT = 'SUSPENDED_ACTION_LIMIT',
  SUSPENDED_MONITORING_LIMIT = 'SUSPENDED_MONITORING_LIMIT',
}

export interface StructType {
  [key: string]: StructType | string;
}

export interface StreamType {
  [key: string]: StructType | string;
}

export interface ArtifactOutputStreams {
  default: StreamType;

  [key: string]: StreamType;
}

export type CustomizationConfig = {
  type: string;
  value?: string;
  children?: CustomizationConfig[];
};

/** aka "KnownCurrency" on backend */
export enum SupportedBlockchains {
  btc = 'btc',
  eth = 'eth',
  dash = 'dash',
  algo = 'algo',
  celo = 'celo',
  bsc = 'bsc',
  heco = 'heco',
  sol = 'sol',
  dot = 'dot',
  matic = 'matic',
  cspr = 'cspr',
}

export type SupportedBlockchainsInEditor = Exclude<
  SupportedBlockchains,
  'celo'
>;

export type QuickMonitoringTriggerSchema = {
  address: string;
  addressSource: string;
  conditions: CustomizationConfig;
  transport: string;
  currency: SupportedBlockchainsInEditor | '';
  isErc20: boolean;
  includingNonVerified: boolean;
  addTriggerName: boolean;
  addProjectName: boolean;
  triggerName?: string;
};

export type ProjectTrigger = {
  id: IdType;
  projectId: IdType;
  status: TriggerStatus;
  disruptionReason?: TriggerDisruptionReason;
  name: string;
  currentCode: string;
  outputStreams: ArtifactOutputStreams;
  quickMonitoringSchema: QuickMonitoringTriggerSchema;
  templateId: string;
};

export type ProjectTriggersResponse = ProjectTrigger[];

export type ProjectTemplate = {
  id: string;
  name: string;
  description: string;
  config: {
    project: { name: string };
    metadata: Pick<ProjectMetadata, 'kind' | 'name' | 'type' | 'fields'>[];
  };
};

export type ProjectTemplatesResponse = Record<string, ProjectTemplate>;

// General ABI type
type AbiItemType = 'function' | 'constructor' | 'event' | 'fallback';
type StateMutabilityType = 'pure' | 'view' | 'nonpayable' | 'payable';

type AbiOutput = {
  name: string;
  type: string;
  // components?: AbiOutput[];
  internalType?: string;
};

interface AbiInput {
  name: string;
  type: string;
  indexed?: boolean;
  // components?: AbiInput[];
  internalType?: string;
}

export type AbiFragmentInfo = {
  name: string;
  sighash: string;
};

export type AbiItem = {
  anonymous?: boolean;
  constant?: boolean;
  inputs?: AbiInput[];
  name?: string;
  outputs?: AbiOutput[];
  payable?: boolean;
  stateMutability?: StateMutabilityType | string;
  type: AbiItemType | string;
  gas?: number;
};

export type CreateAbi = {
  projectId: IdType;
  contractName: string;
  blockchain: string;
  abi: AbiItem[];
  eventsInfo: AbiFragmentInfo[];
  functionsInfo: AbiFragmentInfo[];
};

// PARSIQ API specifics
type SelectedEvents = {
  [eventName: string]: string[];
};

export enum TriggerAbiFragmentType {
  function = 'function',
  event = 'event',
}

export type TriggerAbi = {
  id: string;
  triggerId: string;
  contractAbiId: string;
  fragmentName: string;
  fragmentType: TriggerAbiFragmentType;
  trigger: ProjectTrigger;
};

export type ContractAbi = {
  id: string;
  projectId: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  blockchain: string;
  contractName: string;
  abi: AbiItem[];
  selectedEvents: SelectedEvents;
  eventsInfo?: AbiFragmentInfo[];
  functionsInfo?: AbiFragmentInfo[];
  triggerAbis?: TriggerAbi[];
};

export type UserStreamBlockchain =
  | SupportedBlockchains.eth
  | SupportedBlockchains.bsc
  | SupportedBlockchains.heco
  | SupportedBlockchains.matic;

export type IQBillableBlockchain =
  | SupportedBlockchains.bsc
  | SupportedBlockchains.btc
  | SupportedBlockchains.eth;

export type CreateQuickMonitoringWizardRequest = {
  id?: IdType;
  address: string; // empty if using table name as addressSource
  addressSource: 'custom-address' | string; // 'custom-address' or Table name
  transport: string; // transport ID
  currency: string; // chain
  isErc20: boolean;
  includingNonVerified: boolean;
  conditions: {
    type: 'options';
    children: { type: string; children?: unknown[] }[];
  };
  addTriggerName: boolean;
  addProjectName: boolean;
  triggerName: ''; // always empty for QMW? :shrug:
};

export type SpentActionsData = { usedActions: number; actionsQuota: number };

export enum IntegrationLabel {
  Cryptorank = 'Cryptorank',
  Uppsala = 'Uppsala',
  Crystal = 'Crystal',
}

export enum SpentActionDataStatus {
  available = 'available',
  unavailable = 'unavailable',
  notConnected = 'not_connected',
}

export type Integration = SpentActionsData & {
  name: IntegrationLabel;
  status: SpentActionDataStatus;
};

export type GoogleSheetsDeliveryChannelConfigurationType =
  GoogleSheetsDeliveryChannelCreationConfigurationType & {
    spreadsheetId: string;
  };

export type GoogleSheetsDeliveryChannelCreationConfigurationType = {
  spreadsheetName: string;
  eventFieldsConfig: EventFieldsConfigType;
};

export type EventFieldsConfigType = {
  [eventFieldIdentifier: string]: {
    enabled: boolean;
    mappedColumnName: string;
    formatOption: string;
    order: number;
  };
};

export type DeliveryChannel = {
  id: string;
  config: {
    template?: string;
    googleSheetsConfig?: GoogleSheetsDeliveryChannelConfigurationType;
  };
  triggerId: string;
  transport: Transport;
};

export type PublicProject = {
  id: IdType;
  name: string;
  description: string;
  logoUrl: string;
  currencySymbol: string | null;
  triggerConfig: {
    [triggerId: string]: {
      description: string;
      template: string;
      googleSheetsConfig?: GoogleSheetsDeliveryChannelConfigurationType;
      subscribed: boolean;
    };
  };
};

export type PublicProjectActivity = {
  id: string;
  createdAt: string;
  triggerId: string;
  payload: { from: string; to: string; value: string };
};
