import create from 'zustand';

import type { NotificationTheme } from './Notification';

type NotificationStore = {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  theme: NotificationTheme;
  text: string;
  setContent: (text: string, theme?: NotificationTheme) => void;
};

export const useNotificationStore = create<NotificationStore>((set) => ({
  isVisible: false,
  setVisible: (isVisible) => set(() => ({ isVisible })),
  theme: 'green',
  text: '',
  setContent: (text, theme = 'green') =>
    set(() => ({ isVisible: true, text, theme })),
}));

export const useSetNotification = () =>
  useNotificationStore(({ setContent }) => setContent);
