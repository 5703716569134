import QRCode from 'react-qr-code';

import type { Transport } from 'api';
import { OutsideLinkButton } from 'components/button';
import { StyledLink } from 'components/styled-link';
import { APP_CONFIG } from 'consts/config';

import { Success } from '../Success';

export const ChannelInfo = () => (
  <>
    <p className="my-0 mb-4 text-sm text-pgray-700">
      To finish setting up your{' '}
      <span className="font-bold">Telegram’s Channel bot</span> follow the
      steps:
    </p>
    <ol className="my-0 mb-4 ml-4 list-outside list-decimal text-sm text-pgray-700">
      <li>Go to your Channel’s administrators list</li>
      <li>Click Add Admin</li>
      <li>Type @{APP_CONFIG.telegramBot}</li>
      <li>
        Click on the bot and add it with “Post Messages” permission{' '}
        <span className="font-bold">only</span>.
      </li>
    </ol>
    <p className="my-0 mb-2 text-sm text-pgray-700">
      You have 15 minutes to add the bot, after that you’ll have to start over.
    </p>
    <p className="my-0 mb-6 text-sm text-pgray-700">
      When these steps are done this window will be automatically refreshed.
    </p>
    <p className="my-0 mb-4 text-xs text-pgray-700">
      Adding bot with excessive permission will force it to leave the channel
      and you will have to start over.
    </p>
  </>
);

export const PrivateAndGroupInfo = ({
  transport,
}: {
  transport: Transport;
}) => {
  if (!transport || transport.type !== 'telegram') {
    return null;
  }

  return (
    <>
      <p className="my-0 mb-4 text-pgray-700">
        To finish <span className="font-bold">Telegram</span> configuration
        please{' '}
        <StyledLink href={transport.config.activationUrl}>
          click the button
        </StyledLink>{' '}
        or scan the QR code with your phone.
      </p>

      <div className="mb-4 flex justify-center">
        <QRCode value={transport.config.activationUrl || ''} />
      </div>

      <OutsideLinkButton
        href={transport.config.activationUrl}
        label="Connect Telegram"
        fullWidth
      />
    </>
  );
};

export const TelegramFinalConfiguration = ({
  transport,
}: {
  transport: Transport;
}) => {
  if (!transport || transport.type !== 'telegram') {
    return null;
  }

  if (transport.configured) {
    return <Success />;
  }

  if (transport.config.privacy === 'channel') {
    return <ChannelInfo />;
  }

  return <PrivateAndGroupInfo transport={transport} />;
};
