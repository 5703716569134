export const ROUTE_AUTH_LOGIN = '/login';

export const ROUTE_AUTH_LOGIN_ERROR = '/login-error';

export const ROUTE_AUTH_LOGOUT = '/logout';

export const ROUTE_AUTH_REGISTER = '/signup';

export const ROUTE_PASSWORD_RESET = '/password/reset/:token';

export const ROUTE_FORGOT_PASSWORD = '/password/reset/';

export const ROUTE_AUTH_SOCIAL_LOGIN = '/social/login';

export const ROUTE_AUTH_SOCIAL_CONNECT = '/social/connect';
