import { useController } from 'react-hook-form';
import type { UseControllerProps, FieldValues } from 'react-hook-form';

import type { InputProps } from './InputBase';
import { InputBase } from './InputBase';

export const Input = <TFieldValues extends FieldValues = FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  hidden,
  ...props
}: UseControllerProps<TFieldValues> &
  Omit<InputProps, 'value' | 'onChange'>) => {
  const { field, fieldState } = useController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  if (hidden) {
    return null;
  }

  return (
    <InputBase
      {...props}
      {...field}
      hasError={!!fieldState.error}
      errorText={fieldState.error?.message}
    />
  );
};
