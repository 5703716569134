import type { FC } from 'react';
import { useEffect } from 'react';

import { DiscordForm } from './discord/DiscordForm';
import { GoogleSheetsForm } from './google-sheets/GoogleSheetsForm';
import { SelectTransportType } from './SelectTransportType';
import { useAddTransportStore } from './store';
import { TelegramForm } from './telegram/TelegramForm';
import { TransportFinalConfiguration } from './TransportFinalConfiguration';
import { useStoreTransport } from './useTransport';
import { WebhookForm } from './webhook/WebhookForm';

export const TransportTypeSpecificForm: FC = () => {
  const {
    formData: { type },
  } = useAddTransportStore();

  switch (type) {
    case 'telegram':
      return <TelegramForm />;
    case 'discord':
      return <DiscordForm />;
    case 'google_sheets':
      return <GoogleSheetsForm />;
    case 'webhook':
      return <WebhookForm />;
    default:
      return null;
  }
};

export const AddTransportForm = () => {
  const { step, reset } = useAddTransportStore();
  const transport = useStoreTransport();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (transport) {
    return <TransportFinalConfiguration transport={transport} />;
  }

  if (step === 1) {
    return <SelectTransportType />;
  }

  return <TransportTypeSpecificForm />;
};
