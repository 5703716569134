import React from 'react';

import { renderToString } from 'react-dom/server';
import ReactSelect, { createFilter } from 'react-select';

import { classNames } from 'utils';

import { Label } from '../common/Label';
import { SubLabel } from '../common/SubLabel';
import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { MenuList } from './MenuList';
import { MultiValueLabel } from './MultiValueLabel';
import { MultiValueRemove } from './MultiValueRemove';
import type { SelectProps } from './types';

export const SelectBase = React.forwardRef<React.Ref<unknown>, SelectProps>(
  (props, ref) => (
    <div className="w-full">
      <Label id={props.name} label={props.label} />
      <ReactSelect
        menuPlacement="auto"
        {...props}
        className={classNames(
          'reactSelectContainer',
          props.hasError ? 'hasError' : ''
        )}
        classNamePrefix="reactSelect"
        components={{
          DropdownIndicator,
          MenuList,
          MultiValueLabel,
          MultiValueRemove,
          ClearIndicator,
        }}
        // Filtering
        filterOption={createFilter({
          stringify: (option) =>
            `${renderToString(option.label)} ${option.value}`,
        })}
        // @ts-ignore
        ref={ref}
      />
      <SubLabel
        name={props.name}
        hasError={props.hasError}
        errorText={props.errorText}
        subLabel={props.subLabel}
      />
    </div>
  )
);

SelectBase.displayName = 'SelectBase';
