import { useEffect } from 'react';

import { useMobileView } from 'store';

export const isMobileViewWidth = () => innerWidth <= 640;

export const isTabletOrMobileViewWidth = () => innerWidth <= 1024;

export const useMobileViewTracker = () => {
  const { setIsMobileView } = useMobileView();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        if (isMobileViewWidth()) {
          setIsMobileView(true);
        } else {
          setIsMobileView(false);
        }
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [setIsMobileView]);
};
