import type { HTTPError } from 'ky';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { UseQueryOptions } from 'react-query/types/react/types';

import { api } from './api';
import type {
  IdType,
  MetadataKind,
  ProjectMetadata,
  ProjectMetadataResponse,
  UserdataType,
} from './types';

export const getProjectMetadataKey = (projectId: string) => [
  'projects',
  'meta',
  projectId,
];

export const getProjectMetadata = async (projectId: string) =>
  await api
    .get(`projects/${projectId}/metadata`)
    .json<ProjectMetadataResponse>();

export const useProjectMetadata = (
  projectId: string,
  options?: Omit<
    UseQueryOptions<ProjectMetadataResponse>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<ProjectMetadataResponse>(
    getProjectMetadataKey(projectId),
    async () => await getProjectMetadata(projectId),
    options
  );

export const useCreateProjectMetadata = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ProjectMetadata,
    HTTPError,
    {
      name: string;
      projectId: IdType;
      kind: MetadataKind;
      type?: UserdataType;
      fields?: { type: UserdataType; name: string }[];
    },
    { previousProjectUserData?: ProjectMetadataResponse }
  >(
    async ({ projectId, type, kind, name, fields = [] }) =>
      await api
        .post(`projects/${projectId}/metadata`, {
          json: {
            name,
            type,
            kind,
            fields,
          },
        })
        .json<ProjectMetadata>(),
    {
      onSuccess: (response, { projectId }) => {
        queryClient.setQueryData<ProjectMetadataResponse>(
          getProjectMetadataKey(projectId),
          (old) => [...(old || []), response]
        );
      },
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries(getProjectMetadataKey(projectId));
      },
    }
  );
};

const getMetadataPayloadKey = (metadataId: string) => ['dataset', metadataId];

type PrimitiveValue = { payload: string };
type StructValue = Record<string, string>;
type TableValue = Record<string, string>[];

type DatasetValue = PrimitiveValue | StructValue | TableValue;

export const useDatasetValue = (
  projectId: string,
  id: string,
  options?: Omit<UseQueryOptions<DatasetValue>, 'queryKey' | 'queryFn'>
) =>
  useQuery<DatasetValue, HTTPError>(
    getMetadataPayloadKey(id),
    async () =>
      await api.get(`projects/${projectId}/data/${id}`).json<DatasetValue>(),
    options
  );

export const useUpdateMetadata = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    HTTPError,
    {
      projectId: IdType;
      id: IdType;
      payload:
        | string
        | Record<string, string | Record<string, unknown>>
        | Record<string, string | Record<string, unknown>>[];
    }
  >(
    async ({ projectId, id, payload }) => {
      const json = typeof payload === 'string' ? { payload } : payload;

      return await api
        .put(`projects/${projectId}/data/${id}`, {
          json,
        })
        .json();
    },
    {
      onSettled: (_, __, { id }) => {
        queryClient.invalidateQueries(getMetadataPayloadKey(id));
      },
    }
  );
};

export const useDeleteProjectMetadata = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { projectId: string; metaId: string },
    { previousProjectUserData?: ProjectMetadataResponse }
  >(
    async ({ projectId, metaId }) =>
      await api.delete(`projects/${projectId}/metadata/${metaId}`),
    {
      onMutate: async ({ projectId, metaId }) => {
        await queryClient.cancelQueries(getProjectMetadataKey(projectId));

        const previousProjectUserData =
          queryClient.getQueryData<ProjectMetadataResponse>(
            getProjectMetadataKey(projectId)
          );

        queryClient.setQueryData<ProjectMetadataResponse>(
          getProjectMetadataKey(projectId),
          (old) => old?.filter(({ id }) => id !== metaId) || []
        );

        return { previousProjectUserData };
      },
      onError: (_, { projectId }, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData(
          getProjectMetadataKey(projectId),
          context.previousProjectUserData
        );
      },
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries(getProjectMetadataKey(projectId));
      },
    }
  );
};
