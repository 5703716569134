import type { CSSProperties } from 'react';
import React from 'react';

import { Popover } from '@headlessui/react';

import { useMobileView } from 'store';

export const PopoverPanel = React.forwardRef<
  HTMLDivElement,
  { style?: CSSProperties }
>(({ children, style }, ref) => {
  const { isMobileView } = useMobileView();

  return (
    <Popover.Panel
      ref={ref}
      className="z-popover w-full rounded-t-2xl border-t border-pgray-300 bg-white p-2 shadow-lg sm:w-auto sm:min-w-56 sm:rounded-md sm:border"
      style={{
        ...style,
        ...(isMobileView
          ? {
              position: 'fixed',
              top: 'unset',
              right: 'unset',
              bottom: 0,
              left: 0,
              width: '100%',
              transform: 'unset',
            }
          : {}),
      }}
    >
      {children}
    </Popover.Panel>
  );
});

PopoverPanel.displayName = 'PopoverPanel';
