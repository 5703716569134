import type { FC } from 'react';

import { GenericHeader } from './GenericHeader';
import { LeftSidebar } from './LeftSidebar';

export const GenericLayout: FC = ({ children }) => (
  <div className="flex h-screen overflow-hidden bg-pgray-50">
    <LeftSidebar />
    <div className="flex w-screen flex-1 flex-col px-2 sm:px-8">
      <GenericHeader />
      {children}
    </div>
  </div>
);
