import type { MenuListProps } from 'react-select';
import { components } from 'react-select';

import type { ExtendedSelectProps } from './types';

export const MenuList = (props: MenuListProps & ExtendedSelectProps) => {
  const menuTitle = props.selectProps.extraProps?.menuTitle;

  return (
    <components.MenuList {...props}>
      {menuTitle ? (
        <div className="reactSelect__menu-list-title">{menuTitle}</div>
      ) : null}
      {props.children}
    </components.MenuList>
  );
};
