import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from './api';
import type { DateType, IdType, IQBillableBlockchain } from './types';

export enum PricingPlanName {
  Free = 'Free',
  // Standard = 'Standard',
  // StandardYearly = 'Standard Yearly',
  Pro = 'Pro',
  ProYearly = 'Pro Yearly',
  Business = 'Business',
  BusinessYearly = 'Business Yearly',
  Enterprise = 'Enterprise',
  IQ = 'IQ',
}

export type Plan = {
  id: string;
  name: PricingPlanName;
  price: string;
  interval: 'year' | 'month';
  config: {
    actionsPerMonth: number;
    allowedStreams: string[];
    allowedTransports: string[];
    [key: string]: unknown;
  };
};

export enum SubscriptionType {
  Stripe = 'Stripe',
  Fake = 'fake',
  PRQ = 'PRQ',
  IQ = 'IQ',
}

export type Subscription = {
  status: string;
  usageStartAt: DateType;
  usageResetAt: DateType;
  expiresAt: DateType;
  planId: string;
  plan: Plan;
  type: SubscriptionType;
  isCrystalAccessible: boolean;
  crystalActionsPerMonth: number;
};

export interface BillingUsage {
  usedActions: number;
  actionsQuota: number;
  totalActions: number;
}

export interface BillingPaymentInfo {
  amountDue: number;
  nextPaymentAttemptAt: DateType;
  usageResetAt: DateType;
}

export type MonitoringUsage = Record<
  IQBillableBlockchain,
  {
    used: number;
    available?: number;
  }
>;

export const getSubscriptionKey = () => 'subscription';

export const getBillingUsageKey = () => 'billing-usage';

export const getBillingPaymentInfoKey = () => 'billing-payment-info';

export const getMonitoringUsageKey = () => 'monitoring-usage';

export const getSubscription = async () => {
  try {
    return await api.get(`subscription`).json<Subscription>();
  } catch (e) {
    return null;
  }
};

export const getBillingPaymentInfo = async () =>
  await api.get(`stripe/upcoming-invoice`).json<BillingPaymentInfo>();

export const getBillingUsage = async () =>
  await api.get(`subscription/spent-actions`).json<BillingUsage>();

export const getMonitoringUsage = async () =>
  await api
    .get(`subscription/address-monitoring-usage`)
    .json<MonitoringUsage | null>();

export const useSubscription = (
  options?: Omit<UseQueryOptions<Subscription>, 'queryKey' | 'queryFn'>
) =>
  useQuery<Subscription | null>(getSubscriptionKey(), getSubscription, {
    staleTime: 60000,
    ...options,
  });

export const useBillingUsage = (
  options?: Omit<UseQueryOptions<BillingUsage>, 'queryKey' | 'queryFn'>
) => useQuery<BillingUsage>(getBillingUsageKey(), getBillingUsage, options);

export const useBillingPaymentInfo = (
  options?: Omit<UseQueryOptions<BillingPaymentInfo>, 'queryKey' | 'queryFn'>
) =>
  useQuery<BillingPaymentInfo>(
    getBillingPaymentInfoKey(),
    getBillingPaymentInfo,
    options
  );

export const useMonitoringUsage = (
  options?: Omit<UseQueryOptions<MonitoringUsage>, 'queryKey' | 'queryFn'>
) =>
  useQuery<MonitoringUsage | null>(
    getMonitoringUsageKey(),
    getMonitoringUsage,
    options
  );

export const usePlans = () =>
  useQuery<Plan[]>('plans', async () => {
    const plans = await api.get('subscription/plans').json<Plan[]>();

    return plans
      .filter(({ name }) => !name.includes('Standard'))
      .sort(
        ({ price: priceA }, { price: priceB }) =>
          Number(priceA) - Number(priceB)
      );
  });

export const useCreateSubscription = () =>
  useMutation<{ sessionId: string }, unknown, { selectedPlan: IdType }>(
    async ({ selectedPlan }) =>
      await api
        .post('stripe/subscription', { json: { selectedPlan } })
        .json<{ sessionId: string }>()
  );

export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => await api.delete('stripe/subscription').json(),
    {
      onSettled: () => {
        queryClient.invalidateQueries(getSubscriptionKey());
      },
    }
  );
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { selectedPlan: IdType }>(
    async ({ selectedPlan }) =>
      await api.put('stripe/subscription', { json: { selectedPlan } }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(getSubscriptionKey());
      },
    }
  );
};
