import { useCallback } from 'react';

import { Notification } from './Notification';
import { useNotificationStore } from './store';

export const NotificationRegion = () => {
  const { isVisible, setVisible } = useNotificationStore(
    ({ isVisible, setVisible }) => ({ isVisible, setVisible })
  );

  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end py-6 px-4 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Notification show={isVisible} onClose={onClose} />
      </div>
    </div>
  );
};
