import { useCallback } from 'react';

import type { HTTPError } from 'ky';
import type { FieldValues, UseFormSetError } from 'react-hook-form';

import { getErrorsFromKyResponse } from 'api';
import type { GetErrorFromKyOptions } from 'api/utils';
import { useSetNotification } from 'components/notification';

export const useSetFormErrors = <
  TFieldValues extends FieldValues = FieldValues
>(
  setError: UseFormSetError<TFieldValues>,
  options?: GetErrorFromKyOptions
) => {
  const setNotification = useSetNotification();

  return useCallback(
    async (error: HTTPError) => {
      const errors = await getErrorsFromKyResponse<TFieldValues>(
        error,
        options
      );

      errors.forEach(({ field, message }) => {
        if (field === 'form') {
          // show root form error
          setNotification(message, 'red');
        }
        setError(field, { type: 'manual', message });
      });
    },
    [options, setError, setNotification]
  );
};
