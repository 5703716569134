import type {
  CompilerDataStructUserdataDefinition,
  CompilerDataTypePredefinedTypeAlias,
} from '@parsiq/parsiql/types/compiler.data.definition';

import type {
  SupportedBlockchains,
  SupportedBlockchainsInEditor,
} from 'api/types';

import { validateAddress } from './userdata-validation.pipe';

export type UppercaseSupportedBlockchainsInEditor =
  Uppercase<SupportedBlockchainsInEditor>;

/**
 * each entry creates a function, globally namespaced by it's blockchain, that takes a
 * string literal and statically evaluates it into an address of the said blockchain
 */
export const BLOCKCHAIN_ADDRESS_FUNCTIONS: Record<
  CompilerDataStructUserdataDefinition['name'] &
    UppercaseSupportedBlockchainsInEditor,
  CompilerDataTypePredefinedTypeAlias['id']
> = {
  BTC: 'parsiql.BTC.address',
  DASH: 'parsiql.DASH.address',
  ALGO: 'parsiql.Algorand.address',
  BSC: 'parsiql.BSC.address',
  ETH: 'parsiql.ETH.address',
  HECO: 'parsiql.HECO.address',
  SOL: 'parsiql.Solana.address',
  DOT: 'parsiql.DOT.address',
  CSPR: 'parsiql.CSPR.address',
  MATIC: 'parsiql.MATIC.address',
};

export const lowercase = <T extends string>(keyName: T): Lowercase<T> =>
  keyName.toLowerCase() as Lowercase<T>;

/** the call to this function will be replaced by the result of the static evaluation */
export const makeStaticReplacer = (
  blockchain: UppercaseSupportedBlockchainsInEditor
) => {
  return (address: string) => {
    const currency: keyof typeof SupportedBlockchains = lowercase(blockchain);
    const normalized = validateAddress(address, currency);
    if (normalized) {
      return normalized;
    } else {
      throw new Error(`Invalid ${blockchain} address format`);
    }
  };
};
