import type { ComponentProps } from 'react';

export const LogoIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.63 32.212v5.125h5.135v-1.281h7.061v11.256c-11.829-.64-21.458-10.34-22.1-22.146h11.28v6.955l-1.376.091ZM32.456 36.056v1.28h5.135v-5.124h-1.284v-6.955h11.28c-.643 11.805-10.363 21.414-22.193 22.055V36.056h7.062Z"
      fill="currentColor"
    />
    <path
      d="M22.826 11.988h9.72v-1.556h5.136v5.674h-5.135V14.55h-9.72v10.616l11.004.091v6.955h-1.284v1.281H15.766v-1.28h-1.193V15.556h1.284v-5.125h-5.135v5.125h1.284v6.955H.726C1.369 10.707 11.09 1.098 22.827.458v11.53Z"
      fill="currentColor"
    />
  </svg>
);
