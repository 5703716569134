import type { ComponentProps, ElementType } from 'react';
import { Fragment, useCallback, useEffect } from 'react';

import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';

import { classNames } from 'utils';

import { useNotificationStore } from './store';

const NOTIFICATION_DURATION = 4000;

export type NotificationTheme = 'red' | 'green';

const themeIcon: Record<
  NotificationTheme,
  ElementType<ComponentProps<'svg'>>
> = {
  red: ExclamationIcon,
  green: CheckCircleIcon,
};

type NotificationProps = { show: boolean; onClose: () => void };

const NotificationTracker = () => {
  const setVisible = useNotificationStore(({ setVisible }) => setVisible);

  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    const timeout = setTimeout(onClose, NOTIFICATION_DURATION);

    return () => {
      clearTimeout(timeout);
      onClose();
    };
  }, [onClose]);

  return null;
};

export const Notification = ({ show }: NotificationProps) => {
  const { theme, text } = useNotificationStore(({ theme, text }) => ({
    theme,
    text,
  }));

  const Icon = themeIcon[theme];

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/50">
        <NotificationTracker />
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <Icon
                className={classNames(
                  'h-6 w-6',
                  theme === 'green' ? 'text-pgreen-500' : 'text-pred-500'
                )}
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="font-medium text-pgray-700">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
