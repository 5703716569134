import { useEffect } from 'react';

import { useProjects } from 'api';
import { useActiveProject } from 'store';

export const useSetActiveProject = () => {
  const { data } = useProjects();
  const { activeProject, setActiveProject } = useActiveProject();

  useEffect(() => {
    if (!data) {
      return;
    }

    const selectedActiveProject = data.find(({ id }) => id === activeProject);

    if (!activeProject || !selectedActiveProject) {
      setActiveProject(data[0].id);
    }
  }, [activeProject, data, setActiveProject]);
};
