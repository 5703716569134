import { isAddress as validateEthereumAddress } from 'ethereum-address';
import { validate } from 'multicoin-address-validator';

import type { SupportedBlockchains } from 'api/types';

type NetworkType = 'prod' | 'testnet' | 'both';

// prettier-ignore
export function validateAddress(
  address: string,
  currency: keyof typeof SupportedBlockchains,
  networkType: NetworkType = 'prod',
): string | null {
  // using 'ethereum-address' package validator instead of 'multicoin-address-validator'
  // as the later does not accept valid address without 0x
  const hexValidator = (address: string) =>
    validateEthereumAddress(address) ? address.toLowerCase().padStart(42, '0x') : null;

  const validators: Record<SupportedBlockchains, (address: string) => string | null> = {
    eth: hexValidator,
    bsc: hexValidator,
    heco: hexValidator,
    matic: hexValidator,
    celo: hexValidator,

    sol: address => (validate(address, 'sol', networkType) ? address : null),
    algo: address => (validate(address, 'algo', networkType) ? address : null),
    btc: address => (validate(address, 'Bitcoin', networkType) ? address : null),
    dash: address => (validate(address, 'dash', networkType) ? address : null),
    dot: address => (validate(address, 'dot', networkType) ? address : null),
    cspr: address => (/[0-9a-f]{64}/.test(address) ? address : null),
  };
  const validator = validators[currency];

  return validator(address);
}
