import { useCallback } from 'react';

import create from 'zustand';

type SidePanelStore = {
  sidePanelId: string | null;
  setSidePanel: (
    sidePanelId: string | null,
    props?: Record<string, unknown>
  ) => void;
  props?: Record<string, unknown>;
};

export const useSidePanel = create<SidePanelStore>((set) => ({
  sidePanelId: null,
  setSidePanel: (sidePanelId, props) => {
    set(() => ({ sidePanelId, ...(props ? { props } : {}) }));
  },
  props: {},
}));

export const useCloseSidePanel = () => {
  const { setSidePanel } = useSidePanel();

  return useCallback(() => setSidePanel(null), [setSidePanel]);
};

export const useSidePanelProps = <Props extends Record<string, unknown>>() => {
  const { props } = useSidePanel();

  return props as Props;
};
