export const getLocalStorage = (key) => {
  if (!global.window) {
    return;
  }

  const item = window.localStorage.getItem(key);

  return item ? JSON.parse(item) : undefined;
};

export const setLocalStorage = (key, value) =>
  window?.localStorage.setItem(key, JSON.stringify(value));
