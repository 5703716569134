import produce from 'immer';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from './api';
import type {
  CreateQuickMonitoringWizardRequest,
  DateType,
  DeliveryChannel,
  IdType,
  ProjectTrigger,
  ProjectTriggersResponse,
} from './types';
import { TriggerStatus } from './types';

const getProjectTriggersKey = (projectId: string) => [
  'projects',
  projectId,
  'triggers',
];

const getTriggerKey = (triggerId: IdType) => ['trigger', triggerId];

const getTriggerDeliveryChannelsKey = (triggerId: string) => [
  'trigger',
  triggerId,
  'deliveryChannel',
];

const triggerStatusOrder: Record<TriggerStatus, number> = {
  [TriggerStatus.Deployed]: 1,
  [TriggerStatus.Disabled]: 2,
  [TriggerStatus.Suspended]: 3,
  [TriggerStatus.Created]: 4,
};

export const useProjectTriggers = ({
  projectId,
  options,
}: {
  projectId: string;
  options?: Omit<
    UseQueryOptions<ProjectTriggersResponse>,
    'queryKey' | 'queryFn'
  >;
}) =>
  useQuery<ProjectTriggersResponse>(
    getProjectTriggersKey(projectId),
    async () => {
      const apiResult = await api
        .get(`projects/${projectId}/triggers`)
        .json<ProjectTriggersResponse>();

      return apiResult.sort(
        (
          { status: statusA, name: nameA },
          { status: statusB, name: nameB }
        ) => {
          const statusOrderResult =
            triggerStatusOrder[statusA] - triggerStatusOrder[statusB];

          if (statusOrderResult === 0) {
            return nameA.localeCompare(nameB);
          }

          return statusOrderResult;
        }
      );
    },
    options
  );

const getTrigger = async (projectId: IdType, triggerId: IdType) =>
  await api
    .get(`projects/${projectId}/triggers/${triggerId}`)
    .json<ProjectTrigger>();

export const useTrigger = (
  projectId: IdType,
  triggerId: IdType,
  options?: Omit<UseQueryOptions<ProjectTrigger>, 'queryKey' | 'queryFn'>
) =>
  useQuery<ProjectTrigger>(
    getTriggerKey(triggerId),
    async () => getTrigger(projectId, triggerId),
    { ...options, structuralSharing: false }
  );

export const useCreateTrigger = (
  projectId: IdType,
  options?: Omit<
    UseMutationOptions<ProjectTrigger, unknown, { name: string }>,
    'mutationFn'
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<ProjectTrigger, unknown, { name: string }>(
    async ({ name }) =>
      await api
        .post(`projects/${projectId}/triggers`, {
          json: { name },
        })
        .json(),
    {
      onSuccess: ({ id: triggerId }) => {
        queryClient.prefetchQuery(getTriggerKey(triggerId), async () =>
          getTrigger(projectId, triggerId)
        );
        queryClient.invalidateQueries(getProjectTriggersKey(projectId));
      },
      ...options,
    }
  );
};

export const useRenameTrigger = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ProjectTrigger,
    unknown,
    { name: string; projectId: IdType; triggerId: IdType }
  >(
    async ({ name, projectId, triggerId }) =>
      await api
        .patch(`projects/${projectId}/triggers/${triggerId}`, {
          json: { name },
        })
        .json(),
    {
      onMutate: ({ triggerId, name, projectId }) => {
        queryClient.setQueryData<ProjectTrigger[]>(
          getProjectTriggersKey(projectId),
          (old = []) =>
            produce<ProjectTrigger[]>(old, (draft) => {
              if (!draft) {
                return [];
              }

              const index = draft.findIndex(({ id }) => id === triggerId);

              if (index !== -1) {
                draft[index].name = name;
              }
            })
        );

        queryClient.setQueryData<ProjectTrigger>(
          getTriggerKey(triggerId),
          (old) =>
            produce<ProjectTrigger>(old!, (draft) => {
              draft.name = name;
            })
        );
      },
      onSuccess: ({ projectId }) => {
        queryClient.invalidateQueries(getProjectTriggersKey(projectId));
      },
    }
  );
};

export const useUpsertQuickMonitoringWizardTrigger = (projectId: IdType) => {
  const queryClient = useQueryClient();

  return useMutation<
    ProjectTrigger,
    unknown,
    CreateQuickMonitoringWizardRequest
  >(
    async ({ id, ...variables }) =>
      await api
        .post(`projects/${projectId}/wizard${id ? `/${id}` : ''}`, {
          json: variables,
        })
        .json(),
    {
      onSuccess: ({ id: triggerId }, { id }) => {
        if (id) {
          queryClient.invalidateQueries(getTriggerKey(triggerId));
        } else {
          queryClient.prefetchQuery(getTriggerKey(triggerId), async () =>
            getTrigger(projectId, triggerId)
          );
        }

        queryClient.invalidateQueries(getProjectTriggersKey(projectId));
      },
    }
  );
};

export const useUpdateTriggerCode = (projectId: IdType, triggerId: IdType) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { code: string }>(
    async ({ code }) =>
      await api
        .put(`projects/${projectId}/triggers/${triggerId}/code`, {
          json: { code },
        })
        .json(),
    {
      onSettled: () => {
        queryClient.invalidateQueries(getTriggerKey(triggerId));
      },
    }
  );
};

export const useDeployTriggerCode = (projectId: IdType, triggerId: IdType) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { code: string }>(
    async ({ code }) =>
      await api
        .put(`projects/${projectId}/triggers/${triggerId}/deployment`, {
          json: { code },
        })
        .json(),
    {
      onSettled: () => {
        queryClient.invalidateQueries(getTriggerKey(triggerId));
      },
    }
  );
};

export const useDeleteProjectTrigger = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { projectId: string; triggerId: string },
    { previousProjectTriggersData?: ProjectTriggersResponse }
  >(
    async ({ projectId, triggerId }) =>
      await api.delete(`projects/${projectId}/triggers/${triggerId}`),
    {
      onMutate: async ({ projectId, triggerId }) => {
        await queryClient.cancelQueries(getProjectTriggersKey(projectId));

        const previousProjectTriggersData =
          queryClient.getQueryData<ProjectTriggersResponse>(
            getProjectTriggersKey(projectId)
          );

        queryClient.setQueryData<ProjectTriggersResponse>(
          getProjectTriggersKey(projectId),
          (old) => old?.filter(({ id }) => id !== triggerId) || []
        );

        return { previousProjectTriggersData };
      },
      onError: (_, { projectId }, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData(
          getProjectTriggersKey(projectId),
          context.previousProjectTriggersData
        );
      },
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries(getProjectTriggersKey(projectId));
      },
    }
  );
};

export const useDeliveryChannels = (
  projectId: IdType,
  triggerId: IdType,
  options?: Omit<UseQueryOptions<DeliveryChannel[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<DeliveryChannel[]>(
    getTriggerDeliveryChannelsKey(triggerId),
    async () =>
      await api
        .get(`projects/${projectId}/triggers/${triggerId}/delivery-channels`)
        .json<DeliveryChannel[]>(),
    options
  );

export const useDeleteDeliveryChannel = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      projectId: IdType;
      triggerId: IdType;
      deliveryChannelId: IdType;
    },
    { previousDeliveryChannels?: DeliveryChannel[] }
  >(
    async ({ projectId, triggerId, deliveryChannelId }) =>
      await api.delete(
        `projects/${projectId}/triggers/${triggerId}/delivery-channels/${deliveryChannelId}`
      ),
    {
      onMutate: async ({ triggerId, deliveryChannelId }) => {
        await queryClient.cancelQueries(
          getTriggerDeliveryChannelsKey(triggerId)
        );

        const previousDeliveryChannels = queryClient.getQueryData<
          DeliveryChannel[]
        >(getTriggerDeliveryChannelsKey(triggerId));

        queryClient.setQueryData<DeliveryChannel[]>(
          getTriggerDeliveryChannelsKey(triggerId),
          (old) => old?.filter(({ id }) => id !== deliveryChannelId) || []
        );

        return { previousDeliveryChannels };
      },
      onError: (_, { triggerId }, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData(
          getTriggerDeliveryChannelsKey(triggerId),
          context.previousDeliveryChannels
        );
      },
      onSettled: (_, __, { triggerId }) => {
        queryClient.invalidateQueries(getTriggerDeliveryChannelsKey(triggerId));
      },
    }
  );
};

export type CreateDeliveryChannelProps = {
  projectId: IdType;
  triggerId: IdType;
  template: string;
  transportId: IdType;
  autoGenerateTemplate?: boolean;
  googleSheetsCreationConfig?: GoogleSheetsCreationConfig;
};

type GoogleSheetsCreationConfig = {
  spreadsheetName: string;
  eventFieldsConfig: EventFieldsConfigType;
};

type EventFieldsConfigType = Record<
  string,
  {
    enabled: boolean;
    mappedColumnName: string;
    formatOption: string;
    order: number;
  }
>;

export const useCreateDeliveryChannel = () => {
  const queryClient = useQueryClient();

  return useMutation<DeliveryChannel, unknown, CreateDeliveryChannelProps>(
    async ({
      projectId,
      triggerId,
      template,
      transportId,
      autoGenerateTemplate,
      googleSheetsCreationConfig,
    }) => {
      return await api
        .post(
          `projects/${projectId}/${
            autoGenerateTemplate ? 'wizard' : 'triggers'
          }/${triggerId}/delivery-channels`,
          {
            json: {
              template,
              transportId,
              googleSheetsCreationConfig,
            },
          }
        )
        .json<DeliveryChannel>();
    },
    {
      onSuccess: async (response, { triggerId }) => {
        await queryClient.cancelQueries(
          getTriggerDeliveryChannelsKey(triggerId)
        );

        queryClient.setQueryData<DeliveryChannel[]>(
          getTriggerDeliveryChannelsKey(triggerId),
          (old) => [...(old ?? []), response]
        );
      },
      onSettled: (_, __, { triggerId }) => {
        queryClient.invalidateQueries(getTriggerDeliveryChannelsKey(triggerId));
      },
    }
  );
};

export const useUpdateDeliveryChannel = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DeliveryChannel,
    unknown,
    CreateDeliveryChannelProps & {
      deliveryChannelId: IdType;
    }
  >(
    async ({
      projectId,
      triggerId,
      template,
      transportId,
      deliveryChannelId,
      googleSheetsCreationConfig,
    }) =>
      await api
        .put(
          `projects/${projectId}/triggers/${triggerId}/delivery-channels/${deliveryChannelId}`,
          {
            json: {
              template,
              transportId,
              googleSheetsCreationConfig,
            },
          }
        )
        .json<DeliveryChannel>(),
    {
      onSettled: (_, __, { triggerId }) => {
        queryClient.invalidateQueries(getTriggerDeliveryChannelsKey(triggerId));
      },
    }
  );
};

export const useToggleTrigger = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { projectId: IdType; triggerId: IdType; enabled: boolean },
    {
      previousTrigger?: ProjectTrigger;
      previousProjectTriggers?: ProjectTrigger[];
    }
  >(
    async ({ projectId, triggerId, enabled }) =>
      await api[enabled ? 'put' : 'delete'](
        `projects/${projectId}/triggers/${triggerId}/execution`
      ).json(),
    {
      onMutate: ({ triggerId, enabled, projectId }) => {
        const previousTrigger = queryClient.getQueryData<ProjectTrigger>(
          getTriggerKey(triggerId)
        );

        const previousProjectTriggers = queryClient.getQueryData<
          ProjectTrigger[]
        >(getProjectTriggersKey(projectId));

        queryClient.setQueryData<ProjectTrigger>(
          getTriggerKey(triggerId),
          (old) =>
            produce(old!, (draft) => {
              draft.status = enabled
                ? TriggerStatus.Deployed
                : TriggerStatus.Disabled;
            })
        );

        queryClient.setQueryData<ProjectTrigger[]>(
          getProjectTriggersKey(projectId),
          (old) =>
            produce(old!, (draft) => {
              const triggerIndex = draft.findIndex(
                ({ id }) => triggerId === id
              );

              if (triggerIndex !== 1) {
                draft[triggerIndex].status = enabled
                  ? TriggerStatus.Deployed
                  : TriggerStatus.Disabled;
              }
            })
        );

        return { previousTrigger, previousProjectTriggers };
      },
      onError: (_, { triggerId, projectId }, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData(
          getTriggerKey(triggerId),
          context.previousTrigger
        );

        queryClient.setQueryData(
          getProjectTriggersKey(projectId),
          context.previousProjectTriggers
        );
      },
      onSettled: (_data, _error, { projectId, triggerId }) => {
        queryClient.invalidateQueries(getProjectTriggersKey(projectId));
        queryClient.invalidateQueries(getTriggerKey(triggerId));
      },
    }
  );
};

export type TriggerLogItem = {
  id: IdType;
  level: TriggerLogLevel;
  message: string;
  createdAt: DateType;
  executionId?: string;
  payload: Record<string, string> | null;
};

export type TriggerLogEvent = {
  id: IdType;
  payload: Record<string, string>;
  createdAt: DateType;
  executionId: string;
};

export enum TriggerLogLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export const useTriggerLogs = (
  projectId: IdType,
  triggerId: IdType,
  options?: Omit<UseQueryOptions<TriggerLogItem[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TriggerLogItem[]>(
    ['trigger', 'logs', triggerId],
    async () => {
      const logItems = await api
        .get(`projects/${projectId}/triggers/${triggerId}/logs`)
        .json<TriggerLogItem[]>();

      const eventItems = await api
        .get(`projects/${projectId}/triggers/${triggerId}/events`)
        .json<TriggerLogEvent[]>();

      const eventPayloadsRecord = Object.fromEntries(
        eventItems.map(({ executionId, payload }) => [executionId, payload])
      );

      return logItems
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map(({ id, message, createdAt, level, executionId }) => ({
          id,
          message,
          createdAt,
          level,
          payload: executionId
            ? eventPayloadsRecord[executionId] ?? null
            : null,
        }));
    },
    options
  );

export const useDebugTrigger = () =>
  useMutation<
    Record<string, string>,
    unknown,
    { code: string; message: string; projectId: string; triggerId: string }
  >(async ({ code, message, triggerId, projectId }) => {
    return await api
      .post(`projects/${projectId}/triggers/${triggerId}/debug`, {
        json: { code, message },
      })
      .json();
  });
