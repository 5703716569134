/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const APP_CONFIG: Record<string, string> = {
  backendUrl: process.env.NEXT_PUBLIC_BACKEND_BASE_URL!,
  stripePubkey: process.env.NEXT_PUBLIC_STRIPE_PUBKEY!,
  socialLogins: process.env.NEXT_PUBLIC_FEATURE_SOCIAL_LOGINS!,
  iqServiceNet: process.env.NEXT_PUBLIC_FEATURE_IQ_SERVICE_NET!,
  telegramBot:
    process.env.NODE_ENV === 'production' ? 'ParsiqLiveBot' : 'PSQStagingBot',
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!,
  yandexMetrikaId: process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID!,
  enableTrackers: process.env.NEXT_PUBLIC_ENABLE_TRACKERS!,
};
