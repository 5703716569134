import type { ElementType } from 'react';

import { classNames } from 'utils';

type Props = {
  Icon?: ElementType;
  hasError?: boolean;
};

export const TrailingIcon = ({ Icon, hasError }: Props) => {
  if (!Icon) {
    return null;
  }

  return (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <Icon
        className={classNames(
          'h-5 w-5',
          hasError ? 'text-pred-500' : 'text-pgray-400'
        )}
        aria-hidden="true"
      />
    </div>
  );
};
