import type { FC } from 'react';

import { PlusCircleIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';

import { Button } from 'components/button';

export const AppendArrayField = ({ append }: { append: () => void }) => (
  <div className="border-t border-pgray-100 pt-2">
    <Button
      label="Add more"
      icon={PlusCircleIcon}
      fullWidth
      theme="alpha"
      onClick={append}
    />
  </div>
);

const ArrayHeader = ({
  header,
  onDelete,
}: {
  header: string;
  onDelete?: () => void;
}) => (
  <div className="relative flex items-center justify-between border-t border-pgray-100 pt-2">
    <div className="mr-3 flex items-center justify-center rounded bg-pgray-100 px-2 font-medium text-pgray-700">
      {header}
    </div>
    {onDelete ? (
      <div className="flex items-center justify-center bg-white">
        <button onClick={onDelete}>
          <TrashIcon className="h-4 w-4 text-pred-500" />
        </button>
      </div>
    ) : null}
  </div>
);

export const ArrayFieldContainerOuter: FC = ({ children }) => (
  <div className="flex flex-col space-y-2">{children}</div>
);

export const ArrayFieldContainerInner: FC<{
  index: number;
  remove: (index: number) => void;
  cannotDeleteFirst?: boolean;
}> = ({ children, index, remove, cannotDeleteFirst }) => {
  const onDelete =
    cannotDeleteFirst && index === 0 ? undefined : () => remove(index);

  return (
    <>
      <ArrayHeader header={`${index + 1}`} onDelete={onDelete} />
      <div className="mb-4 flex flex-col space-y-4">{children}</div>
    </>
  );
};
