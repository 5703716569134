import type { PropsWithChildren } from 'react';

import { CheckIcon } from '@heroicons/react/outline';
import type { Control, FieldValues } from 'react-hook-form';
import { useFormState } from 'react-hook-form';

import { classNames } from '../../../utils';

type Props<TFieldValues extends FieldValues = FieldValues> = {
  step: number;
  dependantFields: string[] | boolean;
  title: string;
  subTitle: string;
  control?: Control<TFieldValues>;
  pushChildRight?: boolean;
};

const useIsCompleted = <TFieldValues extends FieldValues = FieldValues>(
  dependantFields: string[] | boolean,
  control?: Control<TFieldValues>
) => {
  const { touchedFields, errors } = useFormState<TFieldValues>({
    control,
  });

  if (typeof dependantFields === 'boolean') {
    return dependantFields;
  }

  const hasErrors = Object.keys(errors).some((key) =>
    dependantFields.includes(key)
  );

  if (hasErrors) {
    return false;
  }

  const dirtyDependantFields = Object.entries(touchedFields).filter(([name]) =>
    dependantFields.includes(name)
  );

  return dirtyDependantFields.length === dependantFields.length
    ? dirtyDependantFields.every(([, value]) => value === true)
    : false;
};

export const FormStep = <TFieldValues extends FieldValues = FieldValues>({
  step,
  control,
  dependantFields,
  title,
  subTitle,
  children,
  pushChildRight,
}: PropsWithChildren<Props<TFieldValues>>) => {
  const isCompleted = useIsCompleted<TFieldValues>(dependantFields, control);

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex items-center">
        <div className="mr-2.5 flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-pblue-50 text-lg font-medium text-pblue-500">
          {isCompleted ? <CheckIcon className="h-6 w-6" /> : step}
        </div>
        <div>
          <div className="text-lg font-medium text-pgray-700">{title}</div>
          <div className="-mt-1 text-xs text-pgray-700">{subTitle}</div>
        </div>
      </div>
      <div className={classNames(pushChildRight ? 'pl-[3.125rem]' : '')}>
        {children}
      </div>
    </div>
  );
};
