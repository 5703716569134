type Props = { id: string; label?: string };

export const Label = ({ label, id }: Props) => {
  if (!label) {
    return null;
  }

  return (
    <label
      htmlFor={id}
      className="mb-1 block text-sm font-medium text-pgray-700"
    >
      {label}
    </label>
  );
};
