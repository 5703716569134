import { ChevronDownIcon } from '@heroicons/react/outline';
import type { DropdownIndicatorProps } from 'react-select';

import { classNames } from 'utils';

export const DropdownIndicator = ({
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps) => (
  <div className="cursor-pointer p-2">
    <ChevronDownIcon
      className={classNames(
        'h-4 w-4 text-pgray-400 transition-transform ease-linear',
        menuIsOpen ? 'rotate-180' : ''
      )}
    />
  </div>
);
