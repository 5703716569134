import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

export const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};

export const EnsureRehydrated: FC = ({ children }) => {
  const didMount = useDidMount();

  if (!didMount) {
    return null;
  }

  return <>{children}</>;
};
