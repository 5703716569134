import type { Control } from 'react-hook-form';

import { useProjectTemplates } from 'api';
import { FormStep, Select } from 'components/form';

import type { FormValues } from './types';

export const ProjectType = ({ control }: { control: Control<FormValues> }) => {
  const { data = [], isLoading } = useProjectTemplates();

  return (
    <FormStep<FormValues>
      step={1}
      control={control}
      dependantFields={['type']}
      title="Project type"
      subTitle="Select your project type"
    >
      <Select<FormValues>
        name="type"
        control={control}
        label="Choose project type"
        placeholder="Project type"
        isLoading={isLoading}
        options={Object.values(data).map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
        rules={{ required: 'Set your project type' }}
      />
    </FormStep>
  );
};
