import React from 'react';

export const GenericContent: React.FC<{ header: React.ReactNode }> = ({
  children,
  header,
}) => (
  <main className="relative z-0 flex flex-1 flex-col overflow-y-auto">
    <div className="mb-4 sm:border-b sm:border-pgray-100 sm:pb-4">{header}</div>
    <div className="flex flex-1 flex-col overflow-y-auto">{children}</div>
  </main>
);
