import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';
import { mixed, object } from 'yup';

import type { TransportType } from 'api/transport';
import type { RadioValue } from 'components/form';
import { FormStep, RadioGroup } from 'components/form';

import { transportOptions } from './Options';
import { PrevNextButtons } from './PrevNextButtons';
import { useAddTransportStore } from './store';

type FormValues = {
  type: RadioValue<TransportType> | null;
};

export const validationSchema = object({
  type: mixed().required('Choose a type'),
});

export const SelectTransportType = () => {
  const { setFormData, setStep, formData } = useAddTransportStore();

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      type:
        transportOptions.find(({ value }) => formData.type === value) ?? null,
    },
  });

  const onSubmit: SubmitHandler<NonNullProps<Required<FormValues>>> = ({
    type,
  }) => {
    setFormData({ type: type.value });
    setStep(2);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStep
        step={1}
        control={control}
        dependantFields={['type']}
        title="Choose transport type"
        subTitle="Select type transport type"
      >
        <RadioGroup
          name="type"
          label="Transport"
          options={transportOptions}
          control={control}
        />
      </FormStep>
      <PrevNextButtons<FormValues> control={control} />
    </form>
  );
};
