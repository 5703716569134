import type { ComponentProps, ElementType, FC } from 'react';

import type { TelegramTransportPrivacy, TransportType } from 'api';
import type { RadioValue } from 'components/form';
import {
  DiscordIcon,
  GoogleSheetsIcon,
  TelegramIcon,
  WebHookIcon,
} from 'components/icon';

const Label: FC<{ icon: ElementType<ComponentProps<'svg'>> }> = ({
  children,
  icon: Icon,
}) => (
  <div className="flex items-center">
    <Icon className="mr-2 w-6" />
    {children}
  </div>
);

export const transportOptions: RadioValue<TransportType>[] = [
  {
    value: 'telegram',
    label: <Label icon={TelegramIcon}>Telegram</Label>,
    description:
      'Use our Telegram bot to deliver messages directly to you or your channel',
  },
  {
    value: 'discord',
    label: <Label icon={DiscordIcon}>Discord</Label>,
    description: 'Deliver messages straight to Discord channel',
  },
  {
    value: 'google_sheets',
    label: <Label icon={GoogleSheetsIcon}>Google Sheets</Label>,
    description: 'Enter data right into the Google Sheets',
  },
  {
    value: 'webhook',
    label: <Label icon={WebHookIcon}>Webhook</Label>,
    description:
      'Use your own web handler to process our data delivered via Webhook',
  },
];

export const transportPrivacyOptions: RadioValue<TelegramTransportPrivacy>[] = [
  {
    value: 'private',
    label: 'Private',
    description: 'Deliver messages directly to you',
  },
  {
    value: 'group',
    label: 'Group',
    description: 'Deliver messages to a Telegram Group',
  },
  {
    value: 'channel',
    label: 'Public Channel',
    description: 'Deliver messages to a Telegram Channel',
  },
];
