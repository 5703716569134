import * as Sentry from '@sentry/nextjs';
import type { HTTPError } from 'ky';
import ky from 'ky';

import { APP_CONFIG } from 'consts/config';
import { ROUTE_AUTH_LOGOUT } from 'features/auth/routes';

const isForbidden = (error: HTTPError) =>
  error.response && error.response.status === 403;

export const redirectToLogout = () => window.location.assign(ROUTE_AUTH_LOGOUT);

const nonAuthRoutes = [`${APP_CONFIG.backendUrl}/auth/whoami`];

export const api = ky.extend({
  prefixUrl: APP_CONFIG.backendUrl,
  credentials: 'include',
  hooks: {
    afterResponse: [
      async (request, _, response) => {
        if (response.status === 401) {
          try {
            await api.put('auth/session');

            return api(request);
          } catch (error) {
            if (isForbidden(error) && !nonAuthRoutes.includes(request.url)) {
              return redirectToLogout();
            }

            return response;
          }
        }

        return response;
      },
      async (request, _, response) => {
        if (response.status >= 500) {
          const errorContent = (await response.clone().json()) as {
            message?: string;
          };

          Sentry.captureException(
            new Error(
              errorContent.message ??
                response.statusText ??
                'Something went wrong'
            ),
            {
              tags: {
                httpStatus: response.status,
              },
              extra: {
                request,
                response,
              },
            }
          );
        }

        return response;
      },
    ],
  },
});
