import { useMutation, useQuery } from 'react-query';

import { useCurrentUser } from '../store';
import { api } from './api';

export type LoginVariables = {
  email: string;
  password: string;
  network?: string;
};

export type LoginResponse = {
  id: string;
  email: string;
};

export type RegisterVariables = {
  email: string;
  password: string;
  password2: string;
  agreeTerms: boolean;
};

export type ForgotPasswordVariables = {
  email: string;
};

export type ResetPasswordVariables = {
  password: string;
  confirmPassword: string;
  token: string;
};

export type UpdatePasswordVariables = {
  oldPassword: string;
  newPassword: string;
};

export const useLogin = () =>
  useMutation<LoginResponse, unknown, LoginVariables>((variables) =>
    api
      .post('auth/local/session', {
        json: variables,
      })
      .json()
  );

export const useLogout = () => useMutation(() => api.delete('auth/session'));

export const usePasswordForgot = () =>
  useMutation<unknown, unknown, ForgotPasswordVariables>((variables) =>
    api.post('auth/password/reset', { json: variables })
  );

export const usePasswordReset = () =>
  useMutation<unknown, unknown, ResetPasswordVariables>((variables) =>
    api.post('auth/password', { json: variables })
  );

export const usePasswordUpdate = () =>
  useMutation<unknown, unknown, UpdatePasswordVariables>((variables) =>
    api.put('auth/password', { json: variables })
  );

export const useRegister = () =>
  useMutation<unknown, unknown, RegisterVariables & { token: string | null }>(
    ({ token, ...variables }) =>
      api
        .post('users', {
          json: variables,
          ...(token ? { searchParams: { token } } : {}),
        })
        .json()
  );

export const useWhoAmi = () => {
  const { setCurrentUser } = useCurrentUser();

  return useQuery<LoginResponse>(
    'whoami',
    () => api.get('auth/whoami').json<LoginResponse>(),
    {
      onSuccess: (currentUser) => {
        setCurrentUser(currentUser);
      },
    }
  );
};
