import type { FC } from 'react';

import { useAddTransportStore } from '../store';
import { TelegramFormStepThree } from './TelegramFormStepThree';
import { TelegramFormStepTwo } from './TelegramFormStepTwo';

export const TelegramForm: FC = () => {
  const { step } = useAddTransportStore();

  switch (step) {
    case 2:
      return <TelegramFormStepTwo />;
    case 3:
      return <TelegramFormStepThree />;
  }

  return null;
};
