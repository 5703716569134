import React from 'react';

import { Dialog } from '@headlessui/react';

import { LogoIcon } from 'components/icon';
import { Section } from 'components/section';
import { SidePanel } from 'components/sidepanel';
import { useCloseSidePanel } from 'store';

import { AddProjectForm } from '../forms/add-project-form/AddProjectForm';

export const ADD_PROJECT_SIDEPANEL_ID = 'ADD_PROJECT_SIDEPANEL_ID';

const Title = () => (
  <Section rounded="2xl" background="gray">
    <div className="flex items-center">
      <div className="mr-4 rounded-lg bg-pgray-200 p-2">
        <LogoIcon className="h-6 w-6 text-pgray-500" />
      </div>
      <Dialog.Title className="text-xl font-medium text-pgray-700">
        New Project
      </Dialog.Title>
    </div>
  </Section>
);

export const AddProjectSidepanel = () => {
  const onClose = useCloseSidePanel();

  return (
    <SidePanel sidePanelId={ADD_PROJECT_SIDEPANEL_ID} title={<Title />}>
      <AddProjectForm onClose={onClose} />
    </SidePanel>
  );
};
