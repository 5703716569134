import type { UseControllerProps, FieldValues } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { SingleValue } from 'react-select';

import { SelectBase } from './SelectBase';
import type { SelectProps, SelectOption } from './types';

/**
 * Instead of using SelectOption as a value for underlying Select, we use just the
 * value of SelectOption, makes data hydration from backend easier.
 *
 * TODO: Move everything to this approach
 */
export const SelectWithFlatValue = <
  TFieldValues extends FieldValues = FieldValues
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  hidden,
  options,
  ...props
}: UseControllerProps<TFieldValues> & SelectProps) => {
  const { field, fieldState } = useController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  if (hidden) {
    return null;
  }

  const value = options
    ? (options as SelectOption[]).find(({ value }) => value === field.value) ??
      null
    : null;

  const onChange = (value: SingleValue<SelectOption>) => {
    field.onChange(value ? value.value : null);
  };

  return (
    <SelectBase
      {...props}
      {...field}
      options={options}
      value={value}
      onChange={onChange}
      hasError={!!fieldState.error}
      errorText={fieldState.error?.message}
    />
  );
};

export const Select = <TFieldValues extends FieldValues = FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  hidden,
  ...props
}: UseControllerProps<TFieldValues> & SelectProps) => {
  const { field, fieldState } = useController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  if (hidden) {
    return null;
  }

  return (
    <SelectBase
      {...props}
      {...field}
      hasError={fieldState.isTouched && !!fieldState.error}
      errorText={fieldState.error?.message}
    />
  );
};
