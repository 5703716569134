import type { Transport } from 'api';

import { Success } from '../Success';

export const WebhookFinalConfiguration = ({
  transport,
}: {
  transport: Transport;
}) => {
  if (!transport || !transport.configured) {
    return null;
  }

  return <Success />;
};
