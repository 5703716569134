import React from 'react';

import { useForm } from 'react-hook-form';

import {
  DefaultFormButtons,
  FormStep,
  FormStepContainer,
  Input,
} from 'components/form';

import { useOnSubmit } from './hooks';
import { ProjectAddress } from './ProjectAddress';
import { ProjectType } from './ProjectType';
import type { FormValues } from './types';

export const AddProjectForm = ({ onClose }: { onClose: () => void }) => {
  const { handleSubmit, control, setError } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      type: null,
      addresses: [{ value: '' }],
    },
  });

  const onSubmit = useOnSubmit({ onClose, setError });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStepContainer>
        <ProjectType control={control} />
        <FormStep<FormValues>
          step={2}
          control={control}
          dependantFields={['name']}
          title="Project data"
          subTitle="Add some data about the project"
        >
          <Input
            name="name"
            control={control}
            label="Set project name"
            placeholder="Project name"
            rules={{ required: 'Set a name for your project' }}
          />
        </FormStep>
        <ProjectAddress control={control} />
      </FormStepContainer>
      <DefaultFormButtons<FormValues> onCancel={onClose} control={control} />
    </form>
  );
};
