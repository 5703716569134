import produce from 'immer';
import type { HTTPError } from 'ky';
import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from './api';
import type { IdType } from './types';

export type TelegramTransportPrivacy = 'private' | 'group' | 'channel';

export type TransportType =
  | 'webhook'
  | 'telegram'
  | 'discord'
  | 'google_sheets';

type GoogleConfig = {
  oAuth2AuthorizationUrl?: string;
};

type WebhookConfig = {
  url?: string;
};

type TelegramConfig = {
  activationUrl: string;
  privacy: TelegramTransportPrivacy;
  channelUsername?: string;
};

type DiscordConfig = {
  activationUrl: string;
};

type TransportBase<
  Type extends TransportType,
  Config extends Record<string, string>
> = {
  id: IdType;
  name: string;
  configured: boolean;
  type: Type;
  config: Config;
};

export type WebHookTransport = TransportBase<'webhook', WebhookConfig>;

export type Transport =
  | TransportBase<'google_sheets', GoogleConfig>
  | WebHookTransport
  | TransportBase<'telegram', TelegramConfig>
  | TransportBase<'discord', DiscordConfig>;

type TransportRequestBase<
  Type extends TransportType,
  Config extends Record<string, string> = Record<string, string>
> = {
  type: Type;
  name: string;
} & Config;

type WebHookTransportRequest = TransportRequestBase<
  'webhook',
  {
    url: string;
  }
>;

type GoogleSheetsTransportRequest = TransportRequestBase<'google_sheets'>;

type TelegramTransportRequest = TransportRequestBase<
  'telegram',
  {
    privacy: TelegramTransportPrivacy;
    publicChannelUsername: string;
  }
>;

type DiscordTransportRequest = TransportRequestBase<'discord'>;

type TransportRequest =
  | WebHookTransportRequest
  | GoogleSheetsTransportRequest
  | TelegramTransportRequest
  | DiscordTransportRequest;

export const useCreateTransport = () => {
  const queryClient = useQueryClient();

  return useMutation<Transport, HTTPError, TransportRequest>(
    async (values) =>
      await api
        .post('transports', {
          json: values,
        })
        .json<Transport>(),
    {
      onSuccess: (response) => {
        queryClient.setQueryData<Transport[]>(getTransportsKey(), (old) => [
          ...(old || []),
          response,
        ]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(getTransportsKey());
      },
    }
  );
};

export const getTransportsKey = () => 'transports';

export const getTransportKey = (transportId: IdType) => [
  'transports',
  transportId,
];

export const getTransports = async () =>
  await api.get('transports').json<Transport[]>();

export const useTransports = (
  options?: Omit<UseQueryOptions<Transport[]>, 'queryKey' | 'queryFn'>
) => useQuery<Transport[]>(getTransportsKey(), getTransports, options);

export const useTransport = (
  transportId: IdType,
  options?: Omit<UseQueryOptions<Transport>, 'queryKey' | 'queryFn'>
) =>
  useQuery<Transport>(
    getTransportKey(transportId),
    async () => await api.get(`transports/${transportId}`).json<Transport>(),
    options
  );

export const useDeleteTransport = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { id: string },
    { previousTransportData?: Transport[] }
  >(async ({ id }) => await api.delete(`transports/${id}`), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries(getTransportsKey());

      const previousTransportData = queryClient.getQueryData<Transport[]>(
        getTransportsKey()
      );

      queryClient.setQueryData<Transport[]>(
        getTransportsKey(),
        (old) => old?.filter(({ id: transportId }) => id !== transportId) || []
      );

      return { previousTransportData };
    },
    onError: (_, __, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData(
        getTransportsKey(),
        context.previousTransportData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(getTransportsKey());
    },
  });
};

export const useEditTransport = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { id: string; name: string } & Record<string, string>,
    { previousTransportData?: Transport[] }
  >(
    async ({ id, ...config }) => {
      const data = queryClient.getQueryData<Transport[]>(getTransportsKey());

      const transport = data!.find(
        ({ id: transportId }) => transportId === id
      )!;

      return api.put(`transports/${id}`, {
        json: { type: transport.type, ...transport.config, ...config },
      });
    },
    {
      onMutate: async ({ id, name, ...config }) => {
        await queryClient.cancelQueries(getTransportsKey());

        const previousTransportData = queryClient.getQueryData<Transport[]>(
          getTransportsKey()
        );

        queryClient.setQueryData<Transport[]>(getTransportsKey(), (old = []) =>
          produce<Transport[]>(old, (draft) => {
            if (!draft) {
              return [];
            }

            const transportIndex = draft.findIndex(
              ({ id: transportId }) => transportId === id
            );

            if (transportIndex !== -1) {
              draft[transportIndex].name = name;
              draft[transportIndex].config = {
                ...draft[transportIndex].config,
                ...config,
              };
            }
          })
        );

        return { previousTransportData };
      },
      onError: (_, __, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData(
          getTransportsKey(),
          context.previousTransportData
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(getTransportsKey());
      },
    }
  );
};
