import type { FC, ReactElement } from 'react';
import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { useCloseSidePanel, useSidePanel } from 'store';

import { Body } from './components/Body';
import { CloseButton } from './components/CloseButton';
import { Overlay } from './components/Overlay';
import { Title } from './components/Title';

type Props = {
  sidePanelId: string;
  title: ReactElement | string;
};

export const SidePanel: FC<Props> = ({ sidePanelId, title, children }) => {
  const { sidePanelId: activeSidePanelId } = useSidePanel();
  const onClose = useCloseSidePanel();

  const isOpen = activeSidePanelId === sidePanelId;

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-rightSidebar overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Overlay />

          <div className="fixed inset-y-0 right-0 flex max-w-full">
            <Body>
              <CloseButton onClose={onClose} />
              <Title title={title} />
              <div className="relative mt-8 flex-1">
                <div className="h-full">{children}</div>
              </div>
            </Body>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
