import type { ReactElement } from 'react';

import { Dialog } from '@headlessui/react';

export const Title = ({ title }: { title: ReactElement | string }) => {
  if (typeof title === 'string') {
    return (
      <Dialog.Title className="text-xl font-medium text-pgray-700">
        {title}
      </Dialog.Title>
    );
  }

  return title;
};
