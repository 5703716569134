import React, { useCallback } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/outline';

import { navlinks } from 'consts/navlinks';
import { useSidebar } from 'store';

import { LeftSidebarMenuItem } from './LeftSidebarMenuItem';
import { ProjectDropdown } from './ProjectDropdown';
import { ProjectTriggers } from './ProjectTriggers';

export const LeftSidebar = () => {
  const { sidebarOpen, setSidebarOpen } = useSidebar();

  const onHide = useCallback(() => setSidebarOpen(false), [setSidebarOpen]);

  if (!sidebarOpen) {
    return null;
  }

  return (
    <>
      <div
        className="absolute inset-0 z-leftSidebarOverlay hidden bg-black opacity-focus transition-opacity sm:block lg:hidden"
        onClick={onHide}
      />
      <div className="absolute z-leftSidebar flex h-screen flex-col overflow-hidden bg-white lg:relative">
        <div className="mx-4 mb-4 flex items-center justify-between border-b border-pgray-100">
          <div className="my-2 mr-4 flex h-12 grow items-center">
            <ProjectDropdown />
          </div>
          <div className="h-5 w-5 text-pgray-400 lg:hidden">
            <button onClick={onHide} className="h-full w-full">
              <ArrowLeftIcon className="h-full w-full" />
            </button>
          </div>
        </div>
        <div className="flex w-screen flex-col overflow-y-auto px-2 sm:w-80">
          <div className="shrink-0">
            {navlinks.map(({ name, route }) => (
              <LeftSidebarMenuItem label={name} key={name} href={route} />
            ))}
          </div>
          <div className="mt-8 mb-2 px-2">
            <div className="w-full border-t border-pgray-100" />
          </div>
          <ProjectTriggers />
        </div>
      </div>
    </>
  );
};
