import type { ButtonProps } from './Button';

export const textStyles: Record<Required<ButtonProps>['size'], string> = {
  tiny: 'text-xs font-medium leading-5',
  small: 'text-sm font-medium',
  medium: 'text-base font-medium',
  large: 'text-lg font-medium',
  huge: 'text-xl font-medium',
};

export const paddingStyles: Record<Required<ButtonProps>['size'], string> = {
  tiny: 'px-3 py-0.5',
  small: 'px-4.5 py-1',
  medium: 'px-6 py-1',
  large: 'px-7 py-1',
  huge: 'px-8 py-1.5',
};

export const paddingStylesLabelAndIcon: Record<
  Required<ButtonProps>['size'],
  string
> = {
  tiny: 'px-2 py-0.5',
  small: 'px-3 py-1',
  medium: 'px-4 py-1',
  large: 'px-5 py-1',
  huge: 'px-6 py-1.5',
};

export const paddingStylesOnlyIcon: Record<
  Required<ButtonProps>['size'],
  string
> = {
  tiny: 'p-1.5',
  small: 'p-1.5',
  medium: 'p-1.5',
  large: 'p-1.5',
  huge: 'p-1.5',
};

export const roundingStyles: Record<Required<ButtonProps>['size'], string> = {
  tiny: 'rounded-md',
  small: 'rounded-md',
  medium: 'rounded-md',
  large: 'rounded-md',
  huge: 'rounded-md',
};

export const iconStyles: Record<Required<ButtonProps>['size'], string> = {
  tiny: 'w-5 h-5 mr-0.5',
  small: 'w-5 h-5 mr-1',
  medium: 'w-6 h-6 mr-1',
  large: 'w-6 h-6 mr-2',
  huge: 'w-6 h-6 mr-1.5',
};

export const iconStylesNoLabel: Record<Required<ButtonProps>['size'], string> =
  {
    tiny: 'w-5 h-5',
    small: 'w-5 h-5',
    medium: 'w-6 h-6',
    large: 'w-6 h-6',
    huge: 'w-6 h-6',
  };

export const colorStyles: Record<
  Required<ButtonProps>['theme'],
  Record<Required<ButtonProps>['color'], string>
> = {
  filled: {
    blue: 'text-white bg-pblue-500',
    gray: 'text-white bg-pgray-500',
    green: 'text-white bg-pgreen-500',
    orange: 'text-white bg-porange-500',
    red: 'text-white bg-pred-500',
  },
  alpha: {
    blue: 'text-pblue-500 bg-pblue-50',
    gray: 'text-pgray-500 bg-pgray-100',
    green: 'text-pgreen-500 bg-pgreen-50',
    orange: 'text-porange-500 bg-porange-50',
    red: 'text-pred-500 bg-pred-50',
  },
  outline: {
    blue: 'text-pblue-500 bg-transparent',
    gray: 'text-pgray-500 bg-transparent',
    green: 'text-pgreen-500 bg-transparent',
    orange: 'text-porange-500 bg-transparent',
    red: 'text-pred-500 bg-transparent',
  },
};

export const borderStyles: Record<
  Required<ButtonProps>['theme'],
  Record<Required<ButtonProps>['color'], string>
> = {
  filled: {
    blue: 'border-transparent',
    gray: 'border-transparent',
    green: 'border-transparent',
    orange: 'border-transparent',
    red: 'border-transparent',
  },
  alpha: {
    blue: 'border-transparent',
    gray: 'border-transparent',
    green: 'border-transparent',
    orange: 'border-transparent',
    red: 'border-transparent',
  },
  outline: {
    blue: 'border-pblue-500',
    gray: 'border-pgray-500',
    green: 'border-pgreen-500',
    orange: 'border-porange-500',
    red: 'border-pred-500',
  },
};

export const hoverFocusStyles: Record<
  Required<ButtonProps>['theme'],
  Record<Required<ButtonProps>['color'], string>
> = {
  filled: {
    blue: 'hover:bg-pblue-700 focus:bg-pblue-700',
    gray: 'hover:bg-pgray-700 focus:bg-pgray-700',
    green: 'hover:bg-pgreen-700 focus:bg-pgreen-700',
    orange: 'hover:bg-porange-700 focus:bg-porange-700',
    red: 'hover:bg-pred-700 focus:bg-pred-700',
  },
  alpha: {
    blue: 'hover:bg-pblue-100 focus:bg-pblue-100',
    gray: 'hover:bg-pgray-200 focus:bg-pgray-200',
    green: 'hover:bg-pgreen-100 focus:bg-pgreen-100',
    orange: 'hover:bg-porange-100 focus:bg-porange-100',
    red: 'hover:bg-pred-100 focus:bg-pred-100',
  },
  outline: {
    blue: 'hover:bg-pblue-50 focus:bg-pblue-50',
    gray: 'hover:bg-pgray-200 focus:bg-pgray-200',
    green: 'hover:bg-pgreen-50 focus:bg-pgreen-50',
    orange: 'hover:bg-porange-100 focus:bg-porange-100',
    red: 'hover:bg-pred-50 focus:bg-pred-50',
  },
};

export const disabledStyles: Record<Required<ButtonProps>['theme'], string> = {
  filled: 'disabled:bg-pgray-300',
  alpha: 'disabled:bg-pgray-200 disabled:text-pgray-300',
  outline:
    'disabled:text-pgray-200 disabled:border-pgray-200 disabled:bg-transparent',
};
