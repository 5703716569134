import { LinearProgress } from 'components/linear-progress';

type UsageProgressProps = {
  description: string;
  used: number;
  quota: number;
};

export const UsageProgress = ({
  description,
  used,
  quota,
}: UsageProgressProps) => {
  const progress = used > quota ? 100 : (used / quota) * 100;

  return (
    <div className="mb-3">
      <div className="mb-1 flex justify-between text-sm text-pgray-500">
        <div>{description}</div>
        <div>
          used {used} of {quota}
        </div>
      </div>
      <LinearProgress value={progress} />
    </div>
  );
};
