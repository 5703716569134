import type { FC } from 'react';
import React from 'react';

import type { LinkProps } from 'next/link';
import Link from 'next/link';

const className = 'font-medium text-pgray-900 no-underline hover:underline';

export const StyledRouterLink: FC<LinkProps> = ({ children, ...props }) => (
  <Link {...props}>
    <a className={className}>{children}</a>
  </Link>
);

/**
 * Use for linking outside of the website
 */
export const StyledLink = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a rel="noopener noreferrer" target="_blank" {...props} className={className}>
    {children}
  </a>
);
