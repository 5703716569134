import { useSubscription } from 'api';
import { useCurrentUser } from 'store';

export const Header = () => {
  const { currentUser } = useCurrentUser();
  const { data: subscription } = useSubscription({ staleTime: 60000 });

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div className="-m-2 rounded-t-2xl bg-pgray-50 p-4 sm:rounded-md">
        <div className="text-sm font-medium">{currentUser.email}</div>
        <div className="text-xs text-pgray-500">
          {subscription ? `${subscription.plan.name} Plan` : 'Free Plan'}
        </div>
      </div>
      <div className="h-2 w-64" />
    </>
  );
};
