import type { ReactNode } from 'react';
import React from 'react';

import { ShieldExclamationIcon } from '@heroicons/react/solid';

export type CheckboxProps = {
  name: string;
  label: ReactNode;
  errorText?: string;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type'
>;

export const CheckboxBase = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, errorText, ...props }, ref) => (
    <div>
      <div className="relative flex items-center">
        <div className="flex h-5 items-center">
          <input
            id={name}
            name={name}
            ref={ref}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-pblue-500 focus:ring-pblue-500"
            {...props}
          />
        </div>
        <div className="ml-3.5 text-sm">
          <label htmlFor={name} className="font-medium text-pgray-700">
            {label}
          </label>
        </div>
      </div>
      {errorText ? (
        <div className="flex items-center text-xs text-pred-600">
          <ShieldExclamationIcon className="mr-1 h-5 w-5" />
          {errorText}
        </div>
      ) : null}
    </div>
  )
);

CheckboxBase.displayName = 'CheckboxBase';
