import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

import { useCurrentUser } from 'store';

import { ROUTE_AUTH_LOGOUT } from '../auth/routes';

export const useLogCurrentUser = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    Sentry.setUser(currentUser);
  }, [currentUser]);
};

export const useLogoutIfNoCurrentUser = () => {
  const { push } = useRouter();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      push(ROUTE_AUTH_LOGOUT);
    }
  }, [currentUser, push]);
};
