import type { ComponentProps } from 'react';

export const GoogleSheetsIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 242423 333333"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <defs>
      <mask id="c">
        <linearGradient
          id="a"
          gradientUnits="userSpaceOnUse"
          x1="200294"
          y1="91174.8"
          x2="200294"
          y2="176113"
        >
          <stop offset="0" stopOpacity=".02" stopColor="#fff" />
          <stop offset="1" stopOpacity=".2" stopColor="#fff" />
        </linearGradient>
        <path fill="url(#a)" d="M158015 84111h84558v99065h-84558z" />
      </mask>
      <mask id="e">
        <radialGradient
          id="b"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="0"
          fx="0"
          fy="0"
        >
          <stop offset="0" stopOpacity="0" stopColor="#fff" />
          <stop offset="1" stopOpacity=".098" stopColor="#fff" />
        </radialGradient>
        <path fill="url(#b)" d="M-150-150h242723v333633H-150z" />
      </mask>
      <radialGradient
        id="f"
        gradientUnits="userSpaceOnUse"
        cx="9696.85"
        cy="10000.4"
        r="166667"
        fx="9696.85"
        fy="10000.4"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" />
      </radialGradient>
      <linearGradient
        id="d"
        gradientUnits="userSpaceOnUse"
        x1="200294"
        y1="95125.2"
        x2="200294"
        y2="172162"
      >
        <stop offset="0" stopColor="#263138" />
        <stop offset="1" stopColor="#263138" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero">
      <path
        d="M151513 0H22729C10227 0 1 10227 1 22728v287877c0 12505 10227 22728 22728 22728h196966c12505 0 22728-10224 22728-22728V90911l-53028-37880L151513 0z"
        fill="#0f9c57"
      />
      <path
        d="M60606 162880v109853h121216V162880H60606zm53032 94698H75757v-18938h37881v18938zm0-30301H75757v-18946h37881v18946zm0-30310H75757v-18936h37881v18936zm53030 60611h-37884v-18938h37884v18938zm0-30301h-37884v-18946h37884v18946zm0-30310h-37884v-18936h37884v18936z"
        fill="#f0f0f0"
      />
      <path
        mask="url(#c)"
        fill="url(#d)"
        d="M158165 84261l84258 84245V90911z"
      />
      <path
        d="M151513 0v68184c0 12557 10173 22727 22727 22727h68183L151513 0z"
        fill="#87cdac"
      />
      <path
        d="M22728 0C10226 0 0 10227 0 22729v1893C0 12123 10227 1894 22728 1894h128784V1H22728z"
        fill="#fff"
        fillOpacity=".2"
      />
      <path
        d="M219694 331443H22728C10226 331443 0 321213 0 308715v1890c0 12505 10227 22728 22728 22728h196966c12505 0 22728-10224 22728-22728v-1890c0 12499-10224 22728-22728 22728z"
        fill="#263138"
        fillOpacity=".2"
      />
      <path
        d="M174239 90911c-12554 0-22727-10170-22727-22727v1893c0 12557 10173 22727 22727 22727h68183v-1893h-68183z"
        fill="#263138"
        fillOpacity=".102"
      />
      <path
        d="M151513 0H22729C10227 0 1 10227 1 22729v287876c0 12505 10227 22728 22728 22728h196966c12505 0 22728-10224 22728-22728V90911L151513 0z"
        mask="url(#e)"
        fill="url(#f)"
      />
    </g>
  </svg>
);
