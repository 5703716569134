import create from 'zustand';

import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

type SidebarStore = {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
};

export const useSidebar = create<SidebarStore>((set) => ({
  sidebarOpen: getLocalStorage('sidebarOpen') || false,
  setSidebarOpen: (open) =>
    set(() => {
      setLocalStorage('sidebarOpen', open);

      return { sidebarOpen: open };
    }),
}));
