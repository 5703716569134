import React from 'react';

import type { MultiValueGenericProps } from 'react-select';
import { components } from 'react-select';

export const MultiValueLabel = (props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props}>
    {React.isValidElement(props.children)
      ? React.cloneElement(props.children, {
          ...props.children.props,
          isMulti: true,
        })
      : props.children}
  </components.MultiValueLabel>
);
