import * as Sentry from '@sentry/nextjs';
import create from 'zustand';

import type { LoginResponse } from 'api/auth';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
type CurrentUserStore = {
  currentUser: LoginResponse | null;
  setCurrentUser: (currentUser: LoginResponse | null) => void;
};

export const useCurrentUser = create<CurrentUserStore>((set) => ({
  currentUser: getLocalStorage('currentUser') || null,
  setCurrentUser: (currentUser) =>
    set(() => {
      setLocalStorage('currentUser', currentUser);
      Sentry.setUser(currentUser);

      return { currentUser };
    }),
}));
