import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useMobileView, useSidebar } from 'store';

import { pageview } from '../utils/gtm';
import { isTabletOrMobileViewWidth } from './useMobileViewTracker';

export const useRouteChangeTracker = () => {
  const router = useRouter();

  const { isMobileView } = useMobileView();
  const { setSidebarOpen } = useSidebar();

  useEffect(() => {
    function handleRouteChange() {
      if (isTabletOrMobileViewWidth()) {
        setSidebarOpen(false);
      }
    }

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', pageview);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', pageview);
    };
  }, [isMobileView, router.events, setSidebarOpen]);
};
