import { useBillingUsage, useMonitoringUsage, useSubscription } from 'api';

import { UsageProgress } from './UsageProgress';

export const BillingUsage = () => {
  const { data: subscription = null } = useSubscription();

  const { data: billingUsage } = useBillingUsage();
  const { data: monitoringUsageData } = useMonitoringUsage();

  return (
    <div className="mx-2 border-b border-pgray-100 py-2">
      <UsageProgress
        description="Actions"
        used={billingUsage?.usedActions ?? 0}
        quota={billingUsage?.actionsQuota ?? 0}
      />
      {subscription?.type === 'IQ' && monitoringUsageData
        ? Object.entries(monitoringUsageData).map(
            ([chainId, { used, available }]) => (
              <UsageProgress
                key={chainId}
                description={chainId.toUpperCase()}
                used={used}
                quota={available ?? used}
              />
            )
          )
        : null}
    </div>
  );
};
