import { useCallback } from 'react';

import { useRouter } from 'next/router';
import type { SubmitHandler, UseFormSetError } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';

import { useCreateProject, useProjectTemplates } from 'api';
import { ROUTE_DASHBOARD } from 'features/dashboard/routes';
import { useSetFormErrors } from 'hooks/useSetFormErrors';
import { useActiveProject } from 'store';

import type { FormValues } from './types';

export const useOnSubmit = ({
  onClose,
  setError,
}: {
  onClose: () => void;
  setError: UseFormSetError<FormValues>;
}) => {
  const { push } = useRouter();
  const { setActiveProject } = useActiveProject();

  const { mutateAsync } = useCreateProject();
  const { data: projectTemplates = [] } = useProjectTemplates();

  const setFormErrors = useSetFormErrors(setError);

  const onSubmit: SubmitHandler<NonNullProps<FormValues>> = useCallback(
    async (values) => {
      try {
        const project = await mutateAsync({
          name: values.name,
          templateId:
            values.type.value !== 'scratch' ? values.type.value : undefined,
          metadataName:
            values.type.value !== 'scratch'
              ? Object.values(projectTemplates).find(
                  ({ id }) => id === values.type.value
                )?.config.metadata[0].name
              : undefined,
          addresses: values.addresses.map(({ value }) => value),
        });

        setActiveProject(project.id);
      } catch (error) {
        setFormErrors(error);
      }

      onClose();

      push(ROUTE_DASHBOARD);
    },
    [
      mutateAsync,
      onClose,
      projectTemplates,
      push,
      setActiveProject,
      setFormErrors,
    ]
  );

  return onSubmit;
};
