import type {
  CompilerMetadata as PqlCompilerMetadata,
  PredefinedTypeAliasItem,
} from '@parsiq/parsiql/types/compilation/compiler-metadata.interface';
import produce from 'immer';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { UppercaseSupportedBlockchainsInEditor } from 'shared-implementation/static.definitions';
import {
  BLOCKCHAIN_ADDRESS_FUNCTIONS,
  makeStaticReplacer,
} from 'shared-implementation/static.definitions';

import { api } from './api';
import type { IdType } from './types';

export type CompilerMetadata = {
  metadata: PqlCompilerMetadata;
  samples: Record<string, unknown>;
};

const aliasIdToBlockchain = new Map(
  Object.entries(BLOCKCHAIN_ADDRESS_FUNCTIONS).map(([blockchain, id]) => [
    id as string,
    blockchain as UppercaseSupportedBlockchainsInEditor,
  ])
);

/** JSON serialization strips validator implementation, so they need to be restored */
function addAddressValidator({ id, type }: PredefinedTypeAliasItem) {
  const blockchain = aliasIdToBlockchain.get(id);
  type.staticReplacer = blockchain
    ? makeStaticReplacer(blockchain)
    : () => {
        throw new Error(
          `This address format is not supported yet: missing validator for ${id}`
        );
      };
}

const getCompilerMetadataKey = (projectId: IdType) => [
  'compiler',
  'metadata',
  projectId,
];

const getCompilerMetadata = async (projectId: IdType) =>
  api.get(`projects/${projectId}/compiler-metadata`).json<CompilerMetadata>();

export const useCompilerMetadata = (
  projectId: IdType,
  options?: Omit<UseQueryOptions<CompilerMetadata>, 'queryKey' | 'queryFn'>
) =>
  useQuery<CompilerMetadata>(
    getCompilerMetadataKey(projectId),
    async () => {
      const data = await getCompilerMetadata(projectId);

      return produce(data, (draft) => {
        draft.metadata.predefinedTypeAliases?.forEach(addAddressValidator);
        draft.metadata.streams = draft.metadata.streams.map((stream) => ({
          ...stream,
          /**
           * Filter out stream fields that do not have a name, otherwise this crashes
           * ParsiQL editor.
           *
           * TODO: Metadata needs to arrive filtered from backend, or implement another fix :sweat:
           */
          fields: stream.fields.filter(({ name }) => name !== null),
        }));
      });
    },
    options
  );
