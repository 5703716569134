import type { HTTPError } from 'ky';
import type { Path, FieldValues } from 'react-hook-form';

export type GetErrorFromKyOptions = {
  formRootError?: string;
  replaceProperties?: Record<string, string>;
};

export const getErrorsFromKyResponse = async <
  TFieldValues extends FieldValues = FieldValues
>(
  error: HTTPError,
  options: GetErrorFromKyOptions = {}
): Promise<{ field: Path<TFieldValues>; message: string }[]> => {
  const { formRootError = 'form', replaceProperties = {} } = options;

  if (!error.response) {
    return [];
  }

  const errorContent = (await error.response.json()) as {
    message:
      | string
      | Array<{ property: string; constraints: Record<string, string> }>;
  };

  if (typeof errorContent.message === 'string') {
    return [
      {
        field: formRootError as Path<TFieldValues>,
        message: errorContent.message,
      },
    ];
  }

  return errorContent.message.map(({ property, constraints }) => ({
    field: (replaceProperties[property] ?? property) as Path<TFieldValues>,
    message: constraints[Object.keys(constraints)[0]],
  }));
};

export const getFieldErrorFromKyResponse = async (
  error: HTTPError,
  field: string,
  options: GetErrorFromKyOptions = {}
) => {
  const errors = await getErrorsFromKyResponse(error, options);

  const responseError = errors.find(
    ({ field: errorField }) => errorField === field
  );

  return responseError?.message ?? null;
};
