import { XIcon } from '@heroicons/react/outline';

export const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <div className="mb-4 flex items-center justify-end">
    <button
      type="button"
      className="rounded-md bg-white text-pgray-500 hover:text-gray-700 focus:text-gray-700"
      onClick={onClose}
    >
      <span className="sr-only">Close panel</span>
      <XIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  </div>
);
