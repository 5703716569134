import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { UseQueryOptions } from 'react-query/types/react/types';

import { api } from 'api';
import type { ContractAbi, CreateAbi, IdType } from 'api/types';

export const useCreateUserStream = () => {
  const queryClient = useQueryClient();

  return useMutation<ContractAbi, unknown, CreateAbi>(
    async ({ projectId, ...values }) =>
      await api
        .post(`projects/${projectId}/abi`, {
          json: values,
        })
        .json<ContractAbi>(),
    {
      onSuccess: (_, { projectId }) => {
        queryClient.fetchQuery(
          getUserStreamsKey(projectId),
          async () =>
            await api.get(`projects/${projectId}/abi`).json<ContractAbi[]>()
        );
      },
    }
  );
};

export const getUserStreamsKey = (projectId: IdType) => [
  'user-streams',
  projectId,
];

export const useProjectUserStreams = (
  projectId: IdType,
  options?: Omit<UseQueryOptions<ContractAbi[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery<ContractAbi[]>(
    getUserStreamsKey(projectId),
    async () =>
      await api.get(`projects/${projectId}/abi`).json<ContractAbi[]>(),
    options
  );

export const useSingleUserStream = (
  id: IdType,
  projectId: IdType,
  options?: Omit<UseQueryOptions<ContractAbi>, 'queryKey' | 'queryFn'>
) =>
  useQuery<ContractAbi>(
    ['user-streams', projectId, id],
    async () =>
      await api.get(`projects/${projectId}/abi/${id}`).json<ContractAbi>(),
    options
  );

export const useDeleteUserStream = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: IdType; projectId: IdType }>(
    async ({ id, projectId }) =>
      await api.delete(`projects/${projectId}/abi/${id}`),
    {
      onMutate: ({ id, projectId }) => {
        queryClient.setQueryData<ContractAbi[]>(
          getUserStreamsKey(projectId),
          (old = []) => old.filter(({ id: abiId }) => abiId !== id)
        );
      },
      onSettled: (_data, _error, { projectId }) => {
        queryClient.invalidateQueries(getUserStreamsKey(projectId));
      },
    }
  );
};
