import QRCode from 'react-qr-code';

import type { Transport } from 'api';
import { OutsideLinkButton } from 'components/button';
import { StyledLink } from 'components/styled-link';

import { Success } from '../Success';

export const DiscordFinalConfiguration = ({
  transport,
}: {
  transport: Transport;
}) => {
  if (!transport || transport.type !== 'discord') {
    return null;
  }

  if (transport.configured) {
    return <Success />;
  }

  return (
    <div className="flex flex-col">
      <p className="my-0 mb-4 text-pgray-700">
        To finish <span className="font-bold">Discord</span> configuration
        please{' '}
        <StyledLink href={transport.config.activationUrl}>
          click the button
        </StyledLink>{' '}
        or scan the QR code with your phone.
      </p>

      <div className="mb-4 flex justify-center">
        <QRCode value={transport.config.activationUrl || ''} />
      </div>

      <OutsideLinkButton
        href={transport.config.activationUrl}
        label="Connect Discord"
        fullWidth
      />

      <p className="mt-2 text-sm text-pgray-700">
        We will ask you to give <code>MANAGE_CHANNELS</code> permission during
        authorisation. No worries, we need it only to create a channel for our
        bot. You can revoke access afterwards.
      </p>
    </div>
  );
};
