import React, { useState } from 'react';

import { Popover as HeadlessPopover, Portal } from '@headlessui/react';
import type { Modifier } from 'react-popper';
import { usePopper } from 'react-popper';

import { sameWidth } from './modifiers';
import { PopoverOverlay } from './PopoverOverlay';
import { PopoverPanel } from './PopoverPanel';
import type { PopoverProps } from './types';

export const Popover: React.FC<PopoverProps> = ({
  children,
  openButton,
  theme = 'popover',
  referenceElementWidth = false,
  popperOptions: { modifiers = [], ...otherPopperOptions } = {},
}) => {
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const [popperElement, setPopperElement] = useState();

  const { styles } = usePopper(referenceElement, popperElement, {
    modifiers: [
      referenceElementWidth ? sameWidth : undefined,
      ...modifiers,
    ].filter((modifier) => !!modifier) as Modifier<string>[],
    ...otherPopperOptions,
  });

  return (
    <HeadlessPopover className="relative w-full">
      {({ open, close }) => (
        <>
          <HeadlessPopover.Button
            className="flex w-full items-center"
            ref={setReferenceElement}
          >
            {React.cloneElement<{ onClick?: () => void }>(openButton, {
              ...openButton.props,
              open,
            })}
          </HeadlessPopover.Button>
          {open ? <PopoverOverlay /> : null}
          {open ? (
            <Portal>
              <PopoverPanel
                // @ts-ignore
                ref={setPopperElement}
                style={styles.popper}
              >
                {React.Children.map(children, (child) => {
                  if (React.isValidElement(child)) {
                    return React.cloneElement<{
                      onClick?: () => void;
                      closeOnClick?: boolean;
                    }>(child, {
                      ...child.props,
                      onClick: () => {
                        child.props.onClick?.();
                        if (
                          typeof child.props.closeOnClick === 'undefined' ||
                          child.props.closeOnClick
                        ) {
                          close();
                        }
                      },
                      close,
                      theme,
                    });
                  }
                })}
              </PopoverPanel>
            </Portal>
          ) : null}
        </>
      )}
    </HeadlessPopover>
  );
};
