import type { Project } from 'api';
import { useProjects } from 'api';
import { useActiveProject } from 'store';

export const useActiveProjectData = (): Project | null => {
  const { data = [] } = useProjects();
  const { activeProject } = useActiveProject();

  if (!data || !data.length) {
    return null;
  }

  const activeProjectData = data.find(({ id }) => id === activeProject);

  if (!activeProjectData) {
    return null;
  }

  return activeProjectData;
};
