import { useQuery } from 'react-query';

import { api } from './api';
import type { Integration } from './types';

export const useIntegrations = () =>
  useQuery<Integration[]>(
    'integration',
    async () =>
      await api
        .get(`subscription/spent-actions-integrations`)
        .json<Integration[]>()
  );
