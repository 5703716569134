import { useCallback, useMemo } from 'react';

import type {
  UseControllerProps,
  FieldValues,
  ControllerRenderProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';

import { RadioGroupBase } from './RadioGroupBase';
import type { RadioGroupProps } from './types';

export const RadioGroup = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  ...props
}: UseControllerProps<TFieldValues> &
  Omit<RadioGroupProps, 'value' | 'onChange'>) => {
  const {
    field: { ref, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    control,
  });

  const onChange: ControllerRenderProps<TFieldValues>['onChange'] = useCallback(
    (event) => {
      field.onBlur();
      field.onChange(event);
    },
    [field]
  );

  // have to run this hack, otherwise it does not work well with defaultValues, sorry
  const value = useMemo(
    () =>
      field.value
        ? props.options.find(({ value: v }) => v === field.value.value)
        : null,
    [field.value, props.options]
  );

  return (
    <RadioGroupBase
      {...props}
      {...field}
      onChange={onChange}
      // @ts-ignore
      value={value}
      hasError={!!fieldState.error}
      errorText={fieldState.error?.message}
    />
  );
};
