import type { ReactNode } from 'react';
import React from 'react';

import Link from 'next/link';

import { classNames } from 'utils';

import {
  borderStyles,
  colorStyles,
  disabledStyles,
  hoverFocusStyles,
  iconStyles,
  iconStylesNoLabel,
  paddingStyles,
  paddingStylesLabelAndIcon,
  paddingStylesOnlyIcon,
  roundingStyles,
  textStyles,
} from './styles';

export interface ButtonProps {
  /**
   * Button contents
   */
  label: ReactNode | null;
  /**
   * How large should the button be?
   */
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
  /**
   * Color of the button
   */
  color?: 'blue' | 'gray' | 'green' | 'orange' | 'red';
  /**
   * Theme of the button
   */
  theme?: 'filled' | 'alpha' | 'outline';
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Optional leading icon
   */
  icon?: React.ElementType;
  /**
   * Disabled state
   */
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const getLabelIconClassNames = ({
  label,
  size,
  icon,
}: Pick<ButtonProps, 'label' | 'icon'> &
  Pick<Required<ButtonProps>, 'size'>) => {
  if (label) {
    return icon ? paddingStylesLabelAndIcon[size] : paddingStyles[size];
  }

  return paddingStylesOnlyIcon[size];
};

const getSharedClassnames = ({
  size = 'medium',
  color = 'blue',
  theme = 'filled',
  fullWidth,
  label,
  icon,
  className,
}: Pick<
  ButtonProps,
  'size' | 'theme' | 'color' | 'fullWidth' | 'label' | 'icon'
> & { className?: string }) =>
  classNames(
    className,
    'inline-flex items-center border focus:outline-none',
    fullWidth ? 'justify-center w-full' : '',
    getLabelIconClassNames({ label, icon, size }),
    textStyles[size],
    roundingStyles[size],
    colorStyles[theme][color],
    hoverFocusStyles[theme][color],
    borderStyles[theme][color],
    disabledStyles[theme]
  );

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'medium',
      color = 'blue',
      theme = 'filled',
      label,
      fullWidth,
      icon: Icon,
      type = 'button',
      ...props
    },
    ref
  ) => (
    <button
      type={type}
      className={getSharedClassnames({
        className: 'disabled:cursor-default',
        size,
        theme,
        color,
        icon: Icon,
        fullWidth,
        label,
      })}
      {...props}
      ref={ref}
    >
      {Icon ? (
        <div className={label ? iconStyles[size] : iconStylesNoLabel[size]}>
          <Icon />
        </div>
      ) : null}
      {label ? label : null}
    </button>
  )
);

Button.displayName = 'Button';

export const OutsideLinkButton = ({
  size = 'medium',
  color = 'blue',
  theme = 'filled',
  label,
  fullWidth,
  icon: Icon,
  ...props
}: Omit<ButtonProps, 'type'> &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >) => (
  <a
    className={getSharedClassnames({
      className: 'cursor-pointer',
      size,
      theme,
      color,
      icon: Icon,
      fullWidth,
      label,
    })}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  >
    {Icon ? (
      <div className={label ? iconStyles[size] : iconStylesNoLabel[size]}>
        <Icon />
      </div>
    ) : null}
    {label ? label : null}
  </a>
);

export const LocalLinkButton = ({
  size = 'medium',
  color = 'blue',
  theme = 'filled',
  fullWidth,
  icon: Icon,
  href,
  children,
  ...props
}: Omit<ButtonProps, 'type' | 'label'> &
  Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'children'
  > & { href: string; children?: string }) => (
  <Link href={href}>
    <a
      className={getSharedClassnames({
        className: 'cursor-pointer',
        size,
        theme,
        color,
        icon: Icon,
        fullWidth,
        label: children,
      })}
      {...props}
    >
      {Icon ? (
        <div className={children ? iconStyles[size] : iconStylesNoLabel[size]}>
          <Icon />
        </div>
      ) : null}
      {children ? children : null}
    </a>
  </Link>
);
