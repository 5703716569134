import React from 'react';

import {
  AtSymbolIcon,
  SelectorIcon,
  TemplateIcon,
} from '@heroicons/react/outline';

import { useProjects } from 'api';
import { Dropdown } from 'components/dropdown';
import { LogoIcon } from 'components/icon';
import { PopoverHeader, PopoverPanelItem } from 'components/popover';
import { ROUTE_PROJECT_LIST } from 'features/projects/routes';
import { ADD_PROJECT_SIDEPANEL_ID } from 'features/projects/sidepanels/AddProjectSidepanel';
import { useActiveProjectData } from 'hooks/useActiveProjectData';
import { useActiveProject, useSidePanel } from 'store';
import { classNames } from 'utils';

const useButtonText = () => {
  const activeProject = useActiveProjectData();

  if (!activeProject) {
    return 'Loading...';
  }

  return activeProject.name;
};

const DropdownButton = ({ open }: { open?: boolean }) => {
  const buttonText = useButtonText();

  return (
    <div
      className={classNames(
        'flex h-12 w-full items-center justify-between p-2 pl-0',
        open ? 'rounded bg-pgray-50' : ''
      )}
    >
      <div className="flex">
        <LogoIcon className="mr-2 h-6 w-6 shrink-0 text-pblue-700" />
        <span className="shrink-0 text-pgray-700">{buttonText}</span>
      </div>
      <SelectorIcon className="h-5 w-5 text-pgray-400" />
    </div>
  );
};

const Border = () => <div className="my-2 w-full border-t border-pgray-100" />;

export const ProjectDropdown = () => {
  const { data = [] } = useProjects();
  const { setActiveProject } = useActiveProject();
  const { setSidePanel } = useSidePanel();

  return (
    <Dropdown openButton={<DropdownButton />}>
      <PopoverHeader>Personal projects</PopoverHeader>
      {data.map(({ id, name }) => (
        <PopoverPanelItem
          key={id}
          leadingIcon={AtSymbolIcon}
          onClick={() => setActiveProject(id)}
        >
          {name}
        </PopoverPanelItem>
      ))}
      <Border />
      <PopoverPanelItem leadingIcon={TemplateIcon} href={ROUTE_PROJECT_LIST}>
        <div className="flex flex-col items-start">
          <span>View All projects</span>
          <span className="text-xs font-normal text-pgray-700">
            List all of your projects
          </span>
        </div>
      </PopoverPanelItem>
      <PopoverPanelItem
        leadingIcon={TemplateIcon}
        onClick={() => setSidePanel(ADD_PROJECT_SIDEPANEL_ID)}
      >
        <div className="flex flex-col items-start">
          <span>Add new project</span>
          <span className="text-xs font-normal text-pgray-700">
            Create a new project
          </span>
        </div>
      </PopoverPanelItem>
    </Dropdown>
  );
};
