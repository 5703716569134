import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
  process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV || 'development';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  tracesSampler: (samplingContext) => {
    if (samplingContext.parentSampled !== undefined) {
      return samplingContext.parentSampled;
    }

    if (samplingContext.transactionContext.name.includes('health')) {
      return 0;
    }

    return 1;
  },
});
