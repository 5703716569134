import type { ComponentProps } from 'react';

export const WebHookIcon = (props: ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 239" {...props}>
    <path
      d="M119.54 100.503c-10.61 17.836-20.775 35.108-31.152 52.25c-2.665 4.401-3.984 7.986-1.855 13.58c5.878 15.454-2.414 30.493-17.998 34.575c-14.697 3.851-29.016-5.808-31.932-21.543c-2.584-13.927 8.224-27.58 23.58-29.757c1.286-.184 2.6-.205 4.762-.367l23.358-39.168C73.612 95.465 64.868 78.39 66.803 57.23c1.368-14.957 7.25-27.883 18-38.477c20.59-20.288 52.002-23.573 76.246-8.001c23.284 14.958 33.948 44.094 24.858 69.031c-6.854-1.858-13.756-3.732-21.343-5.79c2.854-13.865.743-26.315-8.608-36.981c-6.178-7.042-14.106-10.733-23.12-12.093c-18.072-2.73-35.815 8.88-41.08 26.618c-5.976 20.13 3.069 36.575 27.784 48.967z"
      fill="#C73A63"
    />
    <path
      d="M149.841 79.41c7.475 13.187 15.065 26.573 22.587 39.836c38.02-11.763 66.686 9.284 76.97 31.817c12.422 27.219 3.93 59.457-20.465 76.25c-25.04 17.238-56.707 14.293-78.892-7.851c5.654-4.733 11.336-9.487 17.407-14.566c21.912 14.192 41.077 13.524 55.305-3.282c12.133-14.337 11.87-35.714-.615-49.75c-14.408-16.197-33.707-16.691-57.035-1.143c-9.677-17.168-19.522-34.199-28.893-51.491c-3.16-5.828-6.648-9.21-13.77-10.443c-11.893-2.062-19.571-12.275-20.032-23.717c-.453-11.316 6.214-21.545 16.634-25.53c10.322-3.949 22.435-.762 29.378 8.014c5.674 7.17 7.477 15.24 4.491 24.083c-.83 2.466-1.905 4.852-3.07 7.774z"
      fill="#4B4B4B"
    />
    <path
      d="M167.707 187.21h-45.77c-4.387 18.044-13.863 32.612-30.19 41.876c-12.693 7.2-26.373 9.641-40.933 7.29c-26.808-4.323-48.728-28.456-50.658-55.63c-2.184-30.784 18.975-58.147 47.178-64.293c1.947 7.071 3.915 14.21 5.862 21.264c-25.876 13.202-34.832 29.836-27.59 50.636c6.375 18.304 24.484 28.337 44.147 24.457c20.08-3.962 30.204-20.65 28.968-47.432c19.036 0 38.088-.197 57.126.097c7.434.117 13.173-.654 18.773-7.208c9.22-10.784 26.191-9.811 36.121.374c10.148 10.409 9.662 27.157-1.077 37.127c-10.361 9.62-26.73 9.106-36.424-1.26c-1.992-2.136-3.562-4.673-5.533-7.298z"
      fill="#4A4A4A"
    />
  </svg>
);
