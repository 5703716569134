import type { Transport } from 'api';

import { DiscordFinalConfiguration } from './discord/DiscordFinalConfiguration';
import { GoogleSheetsFinalConfiguration } from './google-sheets/GoogleSheetsFinalConfiguration';
import { TelegramFinalConfiguration } from './telegram/TelegramFinalConfiguration';
import { WebhookFinalConfiguration } from './webhook/WebhookFinalConfiguration';

export const TransportFinalConfiguration = ({
  transport,
}: {
  transport?: Transport;
}) => {
  if (!transport) {
    return null;
  }

  switch (transport.type) {
    case 'telegram':
      return <TelegramFinalConfiguration transport={transport} />;
    case 'discord':
      return <DiscordFinalConfiguration transport={transport} />;
    case 'google_sheets':
      return <GoogleSheetsFinalConfiguration transport={transport} />;
    case 'webhook':
      return <WebhookFinalConfiguration transport={transport} />;
  }
};
