import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';
import { object, string } from 'yup';

import { useCreateTransport } from 'api';
import { FormStep, Input, MultiFieldContainer } from 'components/form';
import { Section } from 'components/section';
import { useSetFormErrors } from 'hooks/useSetFormErrors';

import { PrevNextButtons } from '../PrevNextButtons';
import { useAddTransportStore } from '../store';

export const validationSchema = object({
  name: string().required('Name is required'),
});

const Header = () => (
  <div className="mb-4">
    <Section background="gray" rounded="2xl">
      <p className="mb-2 text-sm">
        We will deliver your events to a{' '}
        <span className="font-medium">Google Sheets</span>.
      </p>
      <p className="text-sm">
        Please, give it a name. It will help you to quickly distinguish between
        various transports when you creating a trigger.
      </p>
    </Section>
  </div>
);

type FormValues = {
  name: string;
};

const Form = () => {
  const { setTransportId } = useAddTransportStore();
  const { mutateAsync: createTransport } = useCreateTransport();

  const { handleSubmit, control, setError } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      name: '',
    },
  });

  const setFormErrors = useSetFormErrors(setError);

  const onSubmit: SubmitHandler<NonNullProps<Required<FormValues>>> = async (
    values
  ) => {
    try {
      const transport = await createTransport({
        type: 'google_sheets',
        ...values,
      });

      setTransportId(transport.id);
    } catch (error) {
      setFormErrors(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStep
        step={2}
        control={control}
        dependantFields={['name']}
        title="Set transport parameters"
        subTitle="Name of your transport"
      >
        <MultiFieldContainer>
          <Input
            name="name"
            control={control}
            label="Name"
            placeholder="Name"
          />
        </MultiFieldContainer>
      </FormStep>
      <PrevNextButtons<FormValues> control={control} isFinalStep />
    </form>
  );
};

export const GoogleSheetsForm = () => (
  <div className="flex flex-col">
    <Header />
    <Form />
  </div>
);
