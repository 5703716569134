import type { Control } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';

import {
  AppendArrayField,
  ArrayFieldContainerInner,
  ArrayFieldContainerOuter,
  FormStep,
  Input,
} from 'components/form';

import type { FormValues } from './types';

export const ProjectAddress = ({
  control,
}: {
  control: Control<FormValues>;
}) => {
  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name: 'addresses',
  });

  const type = useWatch<FormValues, 'type'>({ control, name: 'type' });

  if (!type || typeof type === 'string' || type.value === 'scratch') {
    return null;
  }

  const canHaveMultipleAddresses = ['spf-monitor-bitcoin'].includes(type.value);

  return (
    <FormStep<FormValues>
      step={3}
      control={control}
      dependantFields={['addresses']}
      title={
        canHaveMultipleAddresses ? 'Addresses to monitor' : 'Address to monitor'
      }
      subTitle={
        canHaveMultipleAddresses
          ? 'Input addresses you want to monitor'
          : 'Input address you want to monitor'
      }
    >
      <ArrayFieldContainerOuter>
        {fields.map((field, index) => (
          <ArrayFieldContainerInner
            key={field.id}
            index={index}
            remove={remove}
            cannotDeleteFirst
          >
            <Input
              name={`addresses.${index}.value`}
              control={control}
              label="Address"
              placeholder="Address"
              rules={{ required: 'Type in an address you want to monitor' }}
            />
          </ArrayFieldContainerInner>
        ))}
      </ArrayFieldContainerOuter>
      {canHaveMultipleAddresses ? (
        <div className="mt-4">
          <AppendArrayField
            append={() =>
              append({
                value: '',
              })
            }
          />
        </div>
      ) : null}
    </FormStep>
  );
};
