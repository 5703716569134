import Link from 'next/link';
import { useRouter } from 'next/router';

import { classNames } from 'utils';

type Props = { label: string; href: string };

export const LeftSidebarMenuItem = ({ label, href }: Props) => {
  const { route } = useRouter();

  return (
    <Link href={href}>
      <a
        className={classNames(
          'block rounded p-2 text-lg text-pgray-700 hover:bg-pgray-50 focus:bg-pgray-50',
          route === href ? 'bg-pgray-50' : ''
        )}
      >
        {label}
      </a>
    </Link>
  );
};
