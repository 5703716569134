import React, { useEffect } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import type {
  Control,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import { useFormState } from 'react-hook-form';

type Props<TFieldValues extends FieldValues & { form?: string }> = {
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
};

export const GeneralFormError = <
  TFieldValues extends FieldValues = FieldValues
>({
  control,
  register,
}: Props<TFieldValues>) => {
  const { errors } = useFormState<{ form: string }>({
    // @ts-ignore
    control,
  });

  useEffect(() => {
    register('form' as Path<TFieldValues>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!errors.form) {
    return null;
  }

  return (
    <div className="mb-4 flex items-center text-pred-500">
      <div className="mr-2 rounded bg-pred-50 p-2">
        <ExclamationCircleIcon className="h-5 w-5 shrink-0" />
      </div>
      <span className="leading-5">{errors.form.message}</span>
    </div>
  );
};
