import { ROUTE_DASHBOARD } from 'features/dashboard/routes';
import { ROUTE_DATASETS } from 'features/datasets/routes';

export const navlinks = [
  {
    name: 'Dashboard',
    route: ROUTE_DASHBOARD,
  },
  {
    name: 'Datasets',
    route: ROUTE_DATASETS,
  },
];
