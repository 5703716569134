import produce from 'immer';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useActiveProject } from 'store';

import { useSetNotification } from '../components/notification';
import { api } from './api';
import { getProjectMetadata, useUpdateMetadata } from './metadata';
import type { Project, ProjectResponse } from './types';

export const useProjects = () => {
  return useQuery<ProjectResponse>(
    'projects',
    async () => await api.get('projects').json<ProjectResponse>()
  );
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const { mutate: updateMetadata } = useUpdateMetadata();

  return useMutation<
    Project,
    unknown,
    {
      name: string;
      templateId?: string;
      metadataName?: string;
      addresses?: string[];
    }
  >(
    async ({ name, templateId }) =>
      await api
        .post('template/projects', {
          json: {
            payload: { project: { name } },
            ...(templateId ? { templateId } : {}),
          },
        })
        .json<Project>(),
    {
      onSuccess: async (response, { templateId, metadataName, addresses }) => {
        queryClient.setQueryData<Project[]>(['projects'], (old) => [
          ...(old || []),
          response,
        ]);

        const projectId = response.id;
        const projectName = response.name;

        if (
          !templateId ||
          !metadataName ||
          !addresses ||
          addresses?.length === 0
        ) {
          return;
        }

        const metadata = await getProjectMetadata(projectId);
        const metadataToChange = metadata.find(
          ({ name }) => name === metadataName
        );

        if (!metadataToChange) {
          return;
        }

        if (templateId === 'spf-monitor-bitcoin') {
          const caseNumberMetadata = metadata.find(
            ({ name }) => name === 'CaseNumber'
          );
          if (caseNumberMetadata) {
            await updateMetadata({
              projectId,
              id: caseNumberMetadata.id,
              payload: projectName,
            });
          }

          return updateMetadata({
            projectId,
            id: metadataToChange.id,
            payload: addresses.map((address) => ({ address, depth: '1' })),
          });
        }

        return updateMetadata({
          projectId,
          id: metadataToChange.id,
          payload: addresses[0],
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries('projects');
      },
    }
  );
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const setNotification = useSetNotification();
  const { setActiveProject } = useActiveProject();

  return useMutation<
    unknown,
    unknown,
    { id: string },
    { previousProjects?: ProjectResponse }
  >(async ({ id }) => await api.delete(`projects/${id}`), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries('projects');

      const previousProjects =
        queryClient.getQueryData<ProjectResponse>('projects');

      queryClient.setQueryData<ProjectResponse>(
        'projects',
        (old) => old?.filter(({ id: projectId }) => projectId !== id) || []
      );

      return { previousProjects: previousProjects };
    },
    onError: (_, __, context) => {
      if (!context) {
        return;
      }

      setNotification('Unable to delete project', 'red');
      queryClient.setQueryData('projects', context.previousProjects);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('projects');
      setActiveProject('');
      setNotification('Project deleted, active project changed');
    },
  });
};

export const useRenameProject = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { id: string; name: string },
    { previousProjects?: ProjectResponse }
  >(
    async ({ id, name }) =>
      await api.patch(`projects/${id}`, { json: { name } }),
    {
      onMutate: async ({ name }) => {
        await queryClient.cancelQueries('projects');

        const previousProjects =
          queryClient.getQueryData<ProjectResponse>('projects');

        queryClient.setQueryData<ProjectResponse>('projects', (old = []) =>
          produce<ProjectResponse>(old, (draft) => {
            if (!draft) {
              return [];
            }

            const projectIndex = draft.findIndex(
              ({ name: projectName }) => projectName === name
            );

            if (projectIndex !== -1) {
              draft[projectIndex].name = name;
            }
          })
        );

        return { previousProjects: previousProjects };
      },
      onError: (_, __, context) => {
        if (!context) {
          return;
        }

        queryClient.setQueryData('projects', context.previousProjects);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries('projects');
      },
    }
  );
};
